import axios from 'axios';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosConfig.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosConfig.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      axios.defaults.headers.common['Authorization'] = '';
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      // window.location.replace('/');
    }
    return Promise.reject(error);
  }
);

export default axiosConfig;
