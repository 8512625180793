import axiosConfig from './axios';

export const getBootcampDataService = async () => {
  try {
    const response = await axiosConfig.get('/career/gallery');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateBootcampDataService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/career/gallery/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addBootcampDataService = async (data) => {
  try {
    const response = await axiosConfig.post('/career/gallery', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteImageService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/career/gallery/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
