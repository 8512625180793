import { CButton, CFormTextarea } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import AppModal from '../../../component/CoreLayout/AppModal';
import FormControl from '../../../component/FormControl/FormControl';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

export default function AddEditCompetences(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    content: '',
    content_en: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const onChangeDesc = (value) => {
  //   setFormData({ ...formData, content: value });
  // };

  // const onChangeDescEn = (value) => {
  //   setFormData({ ...formData, content_en: value });
  // };

  const saveCompetences = async (event) => {
    event.preventDefault();
    props.changeCompetencesData(formData);
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Competences' : 'Add Competences'}
      visible={props.show}
      closeModal={props.toggleModal}
    >
      <div>
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        {/* <div className="mb-2">
          <label className="form-label">Content</label>
          <ReactQuill
            value={formData?.content ? formData.content : ''}
            theme="snow"
            onChange={onChangeDesc}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Content En</label>
          <ReactQuill
            value={formData?.content_en ? formData.content_en : ''}
            theme="snow"
            onChange={onChangeDescEn}
          />
        </div> */}
        <CFormTextarea
          className="mb-2"
          label="Content"
          rows="2"
          name="content"
          value={formData?.content ? formData.content : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Content En"
          rows="2"
          name="content_en"
          value={formData?.content_en ? formData.content_en : ''}
          onChange={onChange}
        ></CFormTextarea>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton size="sm" color="primary" onClick={saveCompetences}>
          {isEdit ? 'Edit Competences' : 'Add Competences'}
        </CButton>
      </>
    </AppModal>
  );
}
