import axiosConfig from './axios';

export const getThemeListService = async () => {
  try {
    const response = await axiosConfig.get('/theme');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateThemeService = async (data, id) => {
  try {
    const response = await axiosConfig.post(`/theme/${id}?_method=PUT`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const toggleActivationThemeService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/theme/change_is_active/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addThemeService = async (data) => {
  try {
    const response = await axiosConfig.post(`/theme`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteThemeService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/theme/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getThemeService = async () => {
  try {
    const response = await axiosConfig.get('/theme');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const resetThemeService = async () => {
  try {
    const response = await axiosConfig.get(`/theme/reset`);
    return response.data;
  } catch (error) {
    return error;
  }
};
