import { CButton, CFormTextarea } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import AppModal from '../../../../component/CoreLayout/AppModal';
import FormControl from '../../../../component/FormControl/FormControl';
import InputImage from '../../../../component/InputImage/InputImage';

export default function AddEditEvent(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    description: '',
    description_en: '',
    image: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = name === 'image' ? event.target.files[0] : event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const saveEvent = async (event) => {
    event.preventDefault();
    props.changeEventData(formData);
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Event' : 'Add Event'}
      visible={props.show}
      closeModal={props.toggleModal}
    >
      <div>
        <label className="form-label">Image</label>
        <InputImage
          imgSrc={formData?.image ? formData.image : ''}
          name="image"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Description"
          rows="2"
          name="description"
          value={formData?.description ? formData.description : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Description En"
          rows="2"
          name="description_en"
          value={formData?.description_en ? formData.description_en : ''}
          onChange={onChange}
        ></CFormTextarea>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton size="sm" color="primary" onClick={saveEvent}>
          {isEdit ? 'Edit Event' : 'Add Event'}
        </CButton>
      </>
    </AppModal>
  );
}
