import { useState } from 'react';
import CIcon from '@coreui/icons-react';
import { cilPencil, cilTrash } from '@coreui/icons';
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CBadge,
} from '@coreui/react';
import DeletePrompt from '../../component/DeletePrompt';
import AddEditTheme from './AddEditTheme';
import {
  deleteThemeService,
  toggleActivationThemeService,
} from '../../services/theme';

export default function ThemeList(props) {
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isShowDeletePrompt, setIsShowDeletePrompt] = useState(false);
  const [dataId, setDataId] = useState();

  const onDelete = (id) => {
    setDataId(id);
    setIsShowDeletePrompt(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteThemeService(dataId);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  const toggleActivation = async (data, id) => {
    try {
      const formDataObj = new FormData();
      formDataObj.append('is_active', data);
      const response = await toggleActivationThemeService(formDataObj, id);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  const onEdit = (data) => {
    setIsShowAddModal(true);
    setSelectedData(data);
  };

  return (
    <>
      <CTable bordered responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">No.</CTableHeaderCell>
            <CTableHeaderCell scope="col">Name</CTableHeaderCell>
            <CTableHeaderCell scope="col">Header</CTableHeaderCell>
            <CTableHeaderCell scope="col">Footer</CTableHeaderCell>
            <CTableHeaderCell scope="col">Is Active</CTableHeaderCell>
            <CTableHeaderCell scope="col">Action</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {props?.data?.map((d, i) => {
            return (
              <CTableRow key={i}>
                <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                <CTableDataCell>{d.name}</CTableDataCell>

                <CTableDataCell>
                  <img src={d.bg_header} alt="bg header" width={100} />
                </CTableDataCell>
                <CTableDataCell>
                  <img src={d.bg_footer} alt="bg header" width={100} />
                </CTableDataCell>
                <CTableDataCell>
                  {d.is_active === '1' ? (
                    <CBadge
                      title="Click to unactivate theme"
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleActivation('0', d.id)}
                      color="success"
                    >
                      Active
                    </CBadge>
                  ) : (
                    <CBadge
                      title="Click to activate theme"
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleActivation('1', d.id)}
                      color="secondary"
                    >
                      Non Active
                    </CBadge>
                  )}
                </CTableDataCell>
                <CTableDataCell>
                  <div className="table-action-wrapper">
                    <button className="btn-edit">
                      <CIcon icon={cilPencil} onClick={() => onEdit(d)} />
                    </button>
                    <button className="btn-delete">
                      <CIcon icon={cilTrash} onClick={() => onDelete(d.id)} />
                    </button>
                  </div>
                </CTableDataCell>
              </CTableRow>
            );
          })}
        </CTableBody>
      </CTable>
      <AddEditTheme
        data={selectedData}
        show={isShowAddModal}
        toggleModal={() => setIsShowAddModal(false)}
        onResponse={(res) => props.onResponse(res)}
      />
      <DeletePrompt
        show={isShowDeletePrompt}
        toggleModal={() => setIsShowDeletePrompt(false)}
        yes={handleDelete}
      />
    </>
  );
}
