import { useState, useEffect } from 'react';
import FormControl from '../../../../component/FormControl/FormControl';
import AppModal from '../../../../component/CoreLayout/AppModal';
import { CButton, CSpinner } from '@coreui/react';
import {
  addCategoryService,
  updateCategoryService,
} from '../../../../services/client';

export default function AddEditCategory(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (formData.name) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);

    try {
      const response = isEdit
        ? await updateCategoryService(formDataObj, formData.id)
        : await addCategoryService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        name: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Category' : 'Add Category'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <FormControl
          type="text"
          label="name"
          title="Name"
          value={formData?.name ? formData.name : ''}
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          size="sm"
          style={{ minWidth: 125 }}
          color="primary"
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
