import axiosConfig from './axios';

export const getEmployeeListService = async () => {
	try {
		const response = await axiosConfig.get('/profile');
		return response.data;
	} catch (error) {
		return error;
	}
};

export const addEmployee = async (data) => {
	try {
		const response = await axiosConfig.post('/profile', data);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const updateEmployee = async (data, id) => {
	try {
		const response = await axiosConfig.post(
			`/profile/${id}?_method=PUT`,
			data
		);
		return response.data;
	} catch (error) {
		return error;
	}
};

export const deleteEmployee = async (id) => {
	try {
		const response = await axiosConfig.delete(`/profile/${id}`);
		return response.data;
	} catch (error) {
		return error;
	}
};
