import axiosConfig from './axios';

export const getNewsListService = async () => {
  try {
    const response = await axiosConfig.get('/home/news?page=1&limit=1234&order_type=desc&order_column=news_date');
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const addNewsService = async (data) => {
  try {
    const response = await axiosConfig.post('/home/news', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addTag = async (data) => {
  try {
    const response = await axiosConfig.post('/new/home/news/tags/create', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateTag = async (data) => {
  try {
    const response = await axiosConfig.post('/new/home/news/tags/update', data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteTag = async (id) => {
  try {
    const response = await axiosConfig.post('/new/home/news/tags/delete', { id });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateNewsService = async (data, id) => {
  try {
    const response = await axiosConfig.post(`/home/news/${id}?_method=PUT`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteNewsService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/home/news/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
