import axiosConfig from './axios';

export const getBootcampListService = async () => {
  try {
    const response = await axiosConfig.get('/form/bootcamp');
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteBootcampService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/form/bootcamp/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
