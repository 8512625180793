import axiosConfig from './axios';

export const getProductITProListService = async () => {
  try {
    const response = await axiosConfig.get('/product/service');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addProductITProService = async (data) => {
  try {
    const response = await axiosConfig.post('/product/service', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateProductITProService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/product/service/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteProductITProService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/product/service/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
