import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'
import RoutesData from './RoutesData'
// routes config

const AppContent = () => {
	return (
		<CContainer lg>
			<Suspense fallback={<CSpinner color="primary" />}>
				<Routes>
					{RoutesData.map((route, idx) => {
						return (
							route.element && (
								<Route
									key={idx}
									path={route.path}
									exact={route.exact}
									name={route.name}
									element={<route.element />}
								/>
							)
						)
					})}
					<Route path="/" element={<Navigate to="employee" replace />} />
				</Routes>
			</Suspense>
		</CContainer>
	)
}

export default React.memo(AppContent)
