import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CSpinner } from '@coreui/react';
import InputImage from '../../../component/InputImage/InputImage';
import { addSBSItem, updateSBSItem } from '../../../services/solution';
import ReactQuill from 'react-quill';

function AddEditSBSContent(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    side_image: '',
    title: '',
    title_en: '',
    description: '',
    description_en: '',
  });

  useEffect(() => {
    setFormData(
      props.isEdit
        ? { ...props.data }
        : {
            ...{
              side_image: '',
              title: '',
              title_en: '',
              description: '',
              description_en: '',
            },
            id: props?.id,
          }
    );
    setIsEdit(props.isEdit ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = name === 'side_image' ? event.target.files[0] : event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onReset = () => {
    setFormData({
      id: '',
      side_image: '',
      title: '',
      title_en: '',
      description: '',
      description_en: '',
    });
  };

  const saveSBS = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    isEdit
      ? formDataObj.append('id', props?.id)
      : formDataObj.append('product_solution_detail_side_content_id', props?.id);
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);
    if (formData.side_image) {
      typeof formData.side_image !== 'string' && formDataObj.append('side_image', formData.side_image);
    }

    try {
      const response = isEdit ? await updateSBSItem(formDataObj) : await addSBSItem(formDataObj);
      props.changeDetailData(response);
      props.toggleModal();
      onReset();
    } catch (error) {
      alert(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Content' : 'Add Content'}
      visible={props.show}
      closeModal={() => {
        onReset();
        props.toggleModal();
      }}
    >
      <div>
        <label className="form-label">Side Image</label>
        <InputImage
          imgSrc={formData?.side_image ? formData.side_image : ''}
          name="side_image"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title EN"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <label className="form-label">Description</label>
        <ReactQuill
          value={formData?.description ? formData.description : ''}
          theme="snow"
          onChange={(value) => setFormData({ ...formData, description: value })}
        />
        {/* <CFormTextarea
          className="mb-2"
          label="Description"
          rows="4"
          name="description"
          value={formData?.description ? formData.description : ''}
          onChange={onChange}
        ></CFormTextarea> */}
        <br />
        <label className="form-label">Description En</label>
        <ReactQuill
          value={formData?.description_en ? formData.description_en : ''}
          theme="snow"
          onChange={(value) => setFormData({ ...formData, description_en: value })}
        />
        {/* <CFormTextarea
          className="mb-2"
          label="Description En"
          rows="4"
          name="description_en"
          value={formData?.description_en ? formData.description_en : ''}
          onChange={onChange}
        ></CFormTextarea> */}
      </div>
      <>
        <CButton
          size="sm"
          color="secondary"
          onClick={() => {
            onReset();
            props.toggleModal();
          }}
        >
          Cancel
        </CButton>
        <CButton style={{ minWidth: 125 }} size="sm" color="primary" disabled={isSubmitting} onClick={saveSBS}>
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}

export default AddEditSBSContent;
