import axiosConfig from './axios';

export const getFulltimeListService = async () => {
  try {
    const response = await axiosConfig.get('/form/karyawan');
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteFulltimeService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/form/karyawan/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
