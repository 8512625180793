import { useState } from 'react';
import CIcon from '@coreui/icons-react';
import { cilPencil, cilTrash } from '@coreui/icons';
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react';
import { deleteTestimonialService } from '../../../services/testimonial';
import AddEditTestimonial from './AddEditTestimonial';
import DeletePrompt from '../../../component/DeletePrompt';

export default function TestimonialList(props) {
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isShowDeletePrompt, setIsShowDeletePrompt] = useState(false);
  const [dataId, setDataId] = useState();

  const onDelete = (id) => {
    setDataId(id);
    setIsShowDeletePrompt(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteTestimonialService(dataId);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  const onEdit = (data) => {
    setIsShowAddModal(true);
    setSelectedData(data);
  };

  return (
    <>
      <CTable bordered responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">No.</CTableHeaderCell>
            <CTableHeaderCell scope="col">Name</CTableHeaderCell>
            <CTableHeaderCell scope="col">Testimonial Word</CTableHeaderCell>
            <CTableHeaderCell scope="col">Position</CTableHeaderCell>
            <CTableHeaderCell scope="col">Category</CTableHeaderCell>
            <CTableHeaderCell scope="col">Action</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {props?.data?.map((d, i) => {
            return (
              <CTableRow key={i}>
                <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                <CTableDataCell>{d.name}</CTableDataCell>
                <CTableDataCell>{d.testimonial_word}</CTableDataCell>
                <CTableDataCell>{d.position}</CTableDataCell>
                <CTableDataCell style={{ textTransform: 'capitalize' }}>
                  {d.kategori}
                </CTableDataCell>
                <CTableDataCell>
                  <div className="table-action-wrapper">
                    <button className="btn-edit">
                      <CIcon icon={cilPencil} onClick={() => onEdit(d)} />
                    </button>
                    <button className="btn-delete">
                      <CIcon icon={cilTrash} onClick={() => onDelete(d.id)} />
                    </button>
                  </div>
                </CTableDataCell>
              </CTableRow>
            );
          })}
        </CTableBody>
      </CTable>
      <AddEditTestimonial
        data={selectedData}
        show={isShowAddModal}
        toggleModal={() => setIsShowAddModal(false)}
        onResponse={(res) => props.onResponse(res)}
      />
      <DeletePrompt
        show={isShowDeletePrompt}
        toggleModal={() => setIsShowDeletePrompt(false)}
        yes={handleDelete}
      />
    </>
  );
}
