import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import DefaultLayout from '../CoreLayout/DefaultLayout';

const ProtectedRoute = () => {
  const { userInfo } = useSelector((state) => state.user);
  if (!userInfo) {
    return <Navigate to="/login" replace />;
  }
  return <DefaultLayout />;
};

export default ProtectedRoute;
