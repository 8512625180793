import axiosConfig from './axios';

export const getHomeBannerListService = async () => {
  try {
    const response = await axiosConfig.get('/home/banner');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addHomeBannerService = async (data) => {
  try {
    const response = await axiosConfig.post('/home/banner', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateHomeBannerService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/home/banner/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteHomeBannerService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/home/banner/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
