import { cilLowVision } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import React, { useState } from "react";
import './InputPassword.scss';

const InputPassword = (props) =>{
	const [typePassword, setTypePassword] = useState(false);
	const handlerInput = (event) =>{
		props.handlerPassword(event);
	}
	const toggleType = (event) =>{
		event.preventDefault();
		setTypePassword(!typePassword);
	}
	return(
		<div className="input-password-wrapper">
			<input type={typePassword? 'text' : 'password'} name="password" placeholder="Password" onChange={handlerInput} />
			<button className="input-password-button" onClick={toggleType}>
				{!typePassword && <CIcon icon={cilLowVision} size="sm"/>}
			</button>
		</div>
	)
}

export default InputPassword;