import axiosConfig from './axios';

export const getOptyListService = async () => {
  try {
    const response = await axiosConfig.get('/form/klien');
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteOptyService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/form/klien/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
