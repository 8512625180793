import { CAlert } from '@coreui/react';

export default function Alert(props) {
  return (
    <div>
      {props.successMessage && (
        <CAlert color="success">{props.successMessage}</CAlert>
      )}
      {props.errorMessage && (
        <CAlert color="danger">{props.errorMessage}</CAlert>
      )}
    </div>
  );
}
