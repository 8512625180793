import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import InputImage from '../../../component/InputImage/InputImage';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CSpinner } from '@coreui/react';
import {
  addBootcampClassService,
  updateBootcampClassService,
} from '../../../services/bootcamp-class';

export default function AddEditBootcampClass(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    nama_kelas: '',
    nama_kelas_en: '',
    logo: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = name === 'logo' ? event.target.files[0] : event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (formData.nama_kelas && formData.nama_kelas_en && formData.logo) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('nama_kelas', formData.nama_kelas);
    formDataObj.append('nama_kelas_en', formData.nama_kelas_en);
    typeof formData.logo !== 'string' &&
      formDataObj.append('logo', formData.logo);

    try {
      const response = isEdit
        ? await updateBootcampClassService(formDataObj, formData.id)
        : await addBootcampClassService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        nama_kelas: '',
        nama_kelas_en: '',
        logo: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Bootcamp Class' : 'Add Bootcamp Class'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Logo Image</label>
        <InputImage
          imgSrc={formData?.logo ? formData.logo : ''}
          name="logo"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="nama_kelas"
          title="Class Name"
          value={formData?.nama_kelas ? formData.nama_kelas : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="nama_kelas_en"
          title="Class Name En"
          value={formData?.nama_kelas_en ? formData.nama_kelas_en : ''}
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          size="sm"
          style={{ minWidth: 125 }}
          color="primary"
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
