import axiosConfig from './axios';

export const getProductBannerListService = async () => {
  try {
    const response = await axiosConfig.get('/product/banner');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addProductBannerService = async (data) => {
  try {
    const response = await axiosConfig.post('/product/banner', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateProductBannerService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/product/banner/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteProductBannerService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/product/banner/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
