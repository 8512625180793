import axiosConfig from './axios';

export const getClientListService = async () => {
  try {
    const response = await axiosConfig.get('/about/client');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addClientService = async (data) => {
  try {
    const response = await axiosConfig.post('/about/client', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateClientService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/about/client/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteClientService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/about/client/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Category
export const getCategoryListService = async () => {
  try {
    const response = await axiosConfig.get('/category/client');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addCategoryService = async (data) => {
  try {
    const response = await axiosConfig.post('/category/client', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateCategoryService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/category/client/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCategoryService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/category/client/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
