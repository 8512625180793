import { useState, useEffect } from 'react';
import { addNewsService, addTag, deleteTag, updateNewsService } from '../../../services/news';
import FormControl from '../../../component/FormControl/FormControl';
import InputImage from '../../../component/InputImage/InputImage';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CSpinner } from '@coreui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';

const options = [
  { value: 'Event', label: 'Event' },
  { value: 'Announcement', label: 'Announcement' },
  { value: 'Product', label: 'Product' },
  { value: 'Insight', label: 'Insight' },
];

export default function AddEditNews(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [tags, setTags] = useState([]);
  const [initTags, setInitTags] = useState([]);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    news_title: '',
    news_title_en: '',
    news_description: '',
    news_description_en: '',
    news_date: '',
    bg_image: '',
    tags: [],
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
    if (props.data?.tags.length) {
      let dataTags = props.data?.tags.map((t) => {
        return {
          id: t.id,
          value: t.tag_name,
          label: t.tag_name,
        };
      });
      setTags(dataTags);
      setInitTags(dataTags);
    } else {
      setTags([]);
      setInitTags([]);
    }
  }, [props.data]);

  const onTagChange = async (data) => {
    // ddd.filter(d => !ccc.find(c => c.id == d.id))
    if (data.length > initTags.length) {
      setTags(data);
      let tag_name = data[data.length - 1].value;
      try {
        const response = await addTag({ news_id: formData.id, tag_name });
        props.onResponse(response);
      } catch (error) {
        props.onResponse(error);
      } finally {
        props.toggleModal();
      }
    } else {
      setTags(data);
      let r = initTags.filter((d) => !data.find((c) => c.id === d.id));
      if (r.length) {
        try {
          const response = await deleteTag(r[0].id);
          props.onResponse(response);
        } catch (error) {
          props.onResponse(error);
        } finally {
          props.toggleModal();
        }
      }
    }
  };

  const onChange = (event) => {
    const name = event.target.name;
    const value = name === 'bg_image' ? event.target.files[0] : event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onChangeDesc = (value) => {
    setFormData({ ...formData, news_description: value });
  };

  const onChangeDescEn = (value) => {
    setFormData({ ...formData, news_description_en: value });
  };

  const checkInput = () => {
    if (
      formData.news_title &&
      formData.news_title_en &&
      formData.news_description &&
      formData.news_description_en &&
      formData.news_date &&
      formData.bg_image
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('news_title', formData.news_title);
    formDataObj.append('news_title_en', formData.news_title_en);
    formDataObj.append('news_description', formData.news_description);
    formDataObj.append('news_description_en', formData.news_description_en);
    formDataObj.append('news_date', formData.news_date);
    typeof formData.bg_image !== 'string' && formDataObj.append('bg_image', formData.bg_image);

    try {
      const response = isEdit ? await updateNewsService(formDataObj, formData.id) : await addNewsService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        news_title: '',
        news_title_en: '',
        news_description: '',
        news_description_en: '',
        news_date: '',
        bg_image: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit News' : 'Add News'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Background Image</label>
        <InputImage imgSrc={formData?.bg_image ? formData.bg_image : ''} name="bg_image" changeInputImage={onChange} />
        <FormControl
          type="text"
          label="news_title"
          title="Title"
          value={formData?.news_title ? formData.news_title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="news_title_en"
          title="Title En"
          value={formData?.news_title_en ? formData.news_title_en : ''}
          handlerChange={onChange}
        />

        {/* <CFormTextarea className="mb-2" label="Preview Content" rows="4" name="preview"></CFormTextarea>
        <CFormTextarea className="mb-2" label="Preview Content En" rows="4" name="preview_en"></CFormTextarea> */}

        {isEdit ? (
          <div className="my-4">
            <label className="form-label">Tags</label>
            <Select value={tags} options={options} isMulti onChange={onTagChange} />
          </div>
        ) : null}

        <div className="mb-2">
          <label className="form-label">Description</label>
          <ReactQuill
            value={formData?.news_description ? formData.news_description : ''}
            theme="snow"
            onChange={onChangeDesc}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Description En</label>
          <ReactQuill
            value={formData?.news_description_en ? formData.news_description_en : ''}
            theme="snow"
            onChange={onChangeDescEn}
          />
        </div>
        <FormControl
          type="date"
          label="news_date"
          title="Date"
          value={formData?.news_date ? formData.news_date : ''}
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton style={{ minWidth: 125 }} size="sm" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
