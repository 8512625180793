import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CAlert } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import LogoRed from '../../assets/img/logo-red.png';
import InputPassword from '../../component/InputPassword/InputPassword';
import { userLogin } from '../../Redux/UserSlice';
import './Login.scss';

const Login = () => {
  const { loading, userInfo, error } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      navigate('/employee');
    }
  }, [navigate, userInfo]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const loginUser = (e) => {
    e.preventDefault();
    dispatch(userLogin(formData));
  };

  return (
    <div className="login-wrapper">
      {error && (
        <div className="alert">
          <CAlert color="danger">{error}</CAlert>
        </div>
      )}
      <img className="login-logo" src={LogoRed} alt="Indocyber" />
      <div className="login-form-wrapper">
        <form className="login-form">
          <h1>
            <span>IGLO</span> CMS
          </h1>
          <input type="text" name="username" placeholder="Username" onChange={handleChange} />
          <InputPassword handlerPassword={handleChange} />
          <button
            className="login-button mb-2"
            onClick={loginUser}
            disabled={loading || formData.username.length < 1 || formData.password < 1}
          >
            Login
          </button>
        </form>
      </div>
      <div className="login-footer">Copyright © 2022 by Indocyber Global Teknologi</div>
    </div>
  );
};

export default Login;

// import React from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { decrement, increment } from "../../Redux/UserSlice";

// const Login = () =>{

// 	const count = useSelector((state)=>state.user.value);
// 	// const loading = useSelector((state) => {
// 	// 	return state.counter.loading;
// 	// });
// 	// const error = useSelector((state) => {
// 	// 	return state.counter.error;
// 	// });
// 	const dispatch = useDispatch();

// 	return(
// 		<div>
// 			<button
//           onClick={() => dispatch(increment())}>+</button>
// 			<span>{count}</span>
// 			<button onClick={() => dispatch(decrement())}>-</button>
// 		</div>
// 	)
// }

// export default Login;
