import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../services/axios';
// import axios from "axios";

const userToken = localStorage.getItem('access_token')
  ? localStorage.getItem('userToken')
  : null;

export const userLogin = createAsyncThunk(
  'user/login',
  async (data, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      let { username, password } = data;
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await axiosConfig.post('/login', formData, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const initialState = {
  loading: false,
  userInfo: localStorage.getItem('user') || null,
  userToken,
  error: null,
  success: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('access_token'); // deletes token from storage
      localStorage.removeItem('user');
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.userToken = payload.access_token;
      state.userInfo = payload.user;
      state.loading = false;
      localStorage.setItem('access_token', payload.access_token);
      localStorage.setItem('user', JSON.stringify(payload.user));
    },
  },
});

export default userSlice.reducer;
