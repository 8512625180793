import axiosConfig from './axios';

export const getCompanyDataService = async () => {
  try {
    const response = await axiosConfig.get('/company');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateCompanyDataService = async (data, id) => {
  try {
    const response = await axiosConfig.post(`/company/${id}?_method=PUT`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
