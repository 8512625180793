// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/font/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/font/Roboto-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/font/Roboto-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/font/Roboto-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/font/Roboto-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".small-container {\n  max-width: 600px;\n  margin: auto;\n  height: 100%;\n}\n\n@font-face {\n  font-family: \"Inter\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Roboto\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"Roboto-Black\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n  font-family: \"Roboto-Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n@font-face {\n  font-family: \"Roboto-Medium\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}", "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,YAAA;EACA,YAAA;AACD;;AACA;EACC,oBAAA;EACA,4CAAA;AAED;AACA;EACC,qBAAA;EACA,4CAAA;AACD;AAEA;EACC,2BAAA;EACA,4CAAA;AAAD;AAGA;EACC,0BAAA;EACA,4CAAA;AADD;AAKA;EACC,4BAAA;EACA,4CAAA;AAHD","sourcesContent":[".small-container{\n\tmax-width: 600px;\n\tmargin: auto;\n\theight: 100%;\n}\n@font-face {\n\tfont-family: 'Inter';\n\tsrc: url(./assets/font/Inter-Regular.ttf);\n}\n\n@font-face {\n\tfont-family: 'Roboto';\n\tsrc: url(./assets/font/Roboto-Regular.ttf);\n}\n\n@font-face {\n\tfont-family: 'Roboto-Black';\n\tsrc: url(./assets/font/Roboto-Black.ttf);\n}\n\n@font-face {\n\tfont-family: 'Roboto-Bold';\n\tsrc: url(./assets/font/Roboto-Bold.ttf);\n}\n\n\n@font-face {\n\tfont-family: 'Roboto-Medium';\n\tsrc: url(./assets/font/Roboto-Medium.ttf);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
