// import { createStore } from 'redux';

// const initialState = {
// 	sidebarShow: true,
// 	user: {}
// };

// const changeState = (state = initialState, { type, ...rest }) => {
// 	switch (type) {
// 		case 'set':
// 			return { ...state, ...rest }
// 		default:
// 			return state
// 	}
// };

// const store = createStore(changeState);
// export default store;

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './UserSlice';
import sidebarReducer from './SidebarSlice';

export default configureStore({
	reducer: {
		user: userReducer,
		sidebar: sidebarReducer
	},
});
