import axiosConfig from './axios';

export const getCareerBannerListService = async () => {
  try {
    const response = await axiosConfig.get('/career/banner');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addCareerBannerService = async (data) => {
  try {
    const response = await axiosConfig.post('/career/banner', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateCareerBannerService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/career/banner/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCareerBannerService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/career/banner/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
