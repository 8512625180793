import { useState, useEffect } from 'react';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
  CSpinner,
} from '@coreui/react';
import FormControl from '../../../component/FormControl/FormControl';
import InputImage from '../../../component/InputImage/InputImage';
import {
  getBootcampDataService,
  updateBootcampDataService,
} from '../../../services/bootcamp-data';
import { getRandomString } from '../../../helpers';
import Alert from '../../../component/Alert';

function Row(props) {
  return (
    <CTableRow>
      <CTableHeaderCell scope="row">{props.index + 1}</CTableHeaderCell>
      <CTableDataCell>
        <InputImage
          name={`arr[${props.index}][list_foto]`}
          changeInputImage={props.onChange}
        />
      </CTableDataCell>
      <CTableDataCell>
        <div className="table-action-wrapper justify-content-center">
          <button className="btn-delete">
            <CIcon icon={cilTrash} onClick={() => props.onDelete(props.id)} />
          </button>
        </div>
      </CTableDataCell>
    </CTableRow>
  );
}

export default function BootcampData() {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [images, setImages] = useState([]);
  const [dataId, setDataId] = useState();
  const [formData, setFormData] = useState({
    link_video: '',
    email_bootcamp: '',
    detail: [],
  });

  const getData = async () => {
    const response = await getBootcampDataService();
    setFormData({
      link_video: response.data.link_video,
      email_bootcamp: response.data.email_bootcamp,
      detail: response.data.detail,
    });
    setDataId(response.data.id);
    if (response.status !== 200) {
      setErrorMessage(response.response.data.message || response.message);
    }
  };

  const onChange = (event) => {
    const name = event.target.name;
    const value =
      name === 'link_video' || name === 'email_bootcamp'
        ? event.target.value
        : event.target.files[0];

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleResponse = (res) => {
    setSuccessMessage('');
    setErrorMessage('');
    if (res.status === 200) {
      setSuccessMessage(res.message);
      getData();
    } else {
      setErrorMessage(res.response ? res.response.data.message : res.message);
    }
  };

  const onDelete = async (id) => {
    let currentFormData = { ...formData };
    const detailData = currentFormData.detail.filter((d) => d.id !== id);
    currentFormData.detail = detailData;
    setFormData({ ...currentFormData });
  };

  const onAddImage = (data) => {
    setImages([...images, data]);
  };

  const onDeleteImage = (id) => {
    const newImages = images.filter((d) => d.id !== id) ?? [];
    const getImage = images.find((d) => d.id === id);
    delete formData[`arr[${getImage.index}][list_foto]`];
    setImages(
      newImages.map((d, i) => ({
        id: d.id,
        index: i + 1,
      }))
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    const formDataObj = new FormData();
    for (const prop in formData) {
      if (prop !== 'detail') {
        formDataObj.append(prop, formData[prop]);
      }
    }
    for (const [index, data] of formData.detail.entries()) {
      formDataObj.append(`arr[${index}][id]`, data.id);
      typeof data.list_foto !== 'string' &&
        formDataObj.append(`arr[${index}][list_foto]`, data.list_foto);
    }

    try {
      const response = await updateBootcampDataService(formDataObj, dataId);
      handleResponse(response);
      setIsSubmitting(false);
      setImages([]);
    } catch (error) {
      handleResponse(error);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>Bootcamp Gallery</span>
        </CCardHeader>
        <CCardBody>
          <div className="mb-4">
            <FormControl
              type="text"
              label="link_video"
              title="Link Video"
              value={formData?.link_video ? formData.link_video : ''}
              handlerChange={onChange}
            />
            <FormControl
              type="email"
              label="email_bootcamp"
              title="Email"
              value={formData?.email_bootcamp ? formData.email_bootcamp : ''}
              handlerChange={onChange}
            />
          </div>
          <div className="mb-2">
            <label className="form-label">Detail Gallery</label>
            <CButton
              onClick={() =>
                onAddImage({
                  id: getRandomString(),
                  index: images.length + formData?.detail?.length,
                })
              }
              size="sm"
              className="float-end"
              color="primary"
            >
              Add Detail
            </CButton>
          </div>
          <CTable bordered responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">Foto</CTableHeaderCell>
                <CTableHeaderCell scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {formData?.detail?.map((d, i) => {
                return (
                  <CTableRow key={i}>
                    <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                    <CTableDataCell>
                      <InputImage
                        imgSrc={d?.list_foto ? d.list_foto : ''}
                        name={`arr[${i}][list_foto]`}
                        changeInputImage={onChange}
                      />
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="table-action-wrapper justify-content-center">
                        <button className="btn-delete">
                          <CIcon
                            icon={cilTrash}
                            onClick={() => onDelete(d.id)}
                          />
                        </button>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                );
              })}
              {images?.map((d) => (
                <Row
                  key={d.id}
                  index={d.index}
                  id={d.id}
                  onChange={onChange}
                  onDelete={() => onDeleteImage(d.id)}
                />
              ))}
            </CTableBody>
          </CTable>
          <CButton
            style={{ minWidth: 125 }}
            size="sm"
            color="primary"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
          </CButton>
        </CCardBody>
      </CCard>
    </>
  );
}
