import axiosConfig from './axios';

export const getJobListService = async () => {
  try {
    const response = await axiosConfig.get('/career/job');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addJobService = async (data) => {
  try {
    const response = await axiosConfig.post('/career/job', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateJobService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/career/job/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteJobService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/career/job/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
