import { useState } from 'react';
import CIcon from '@coreui/icons-react';
import { cilPencil, cilTrash } from '@coreui/icons';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react';
import { deleteNewsService } from '../../../services/news';
import AddEditNews from './AddEditNews';
import DeletePrompt from '../../../component/DeletePrompt';
import JwPagination from 'jw-react-pagination';

export default function NewsList({ list, onResponse, setPageOfItems, pageOfItems }) {
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isShowDeletePrompt, setIsShowDeletePrompt] = useState(false);
  const [dataId, setDataId] = useState();

  const onDelete = (id) => {
    setDataId(id);
    setIsShowDeletePrompt(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteNewsService(dataId);
      onResponse(response);
    } catch (error) {
      onResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  const onEdit = (data) => {
    setIsShowAddModal(true);
    setSelectedData(data);
  };

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  return (
    <>
      <CTable bordered responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">No.</CTableHeaderCell>
            <CTableHeaderCell scope="col">Title</CTableHeaderCell>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Action</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {pageOfItems &&
            pageOfItems.map((d, i) => {
              return (
                <CTableRow key={i}>
                  <CTableHeaderCell scope="row">{d.index}</CTableHeaderCell>
                  <CTableDataCell>{d.news_title}</CTableDataCell>
                  <CTableDataCell>{d.news_date}</CTableDataCell>
                  <CTableDataCell>
                    <div className="table-action-wrapper">
                      <button className="btn-edit">
                        <CIcon icon={cilPencil} onClick={() => onEdit(d)} />
                      </button>
                      <button className="btn-delete">
                        <CIcon icon={cilTrash} onClick={() => onDelete(d.id)} />
                      </button>
                    </div>
                  </CTableDataCell>
                </CTableRow>
              );
            })}
        </CTableBody>
      </CTable>
      <JwPagination pageSize={5} items={list} onChangePage={onChangePage} />
      <AddEditNews
        data={selectedData}
        show={isShowAddModal}
        toggleModal={() => setIsShowAddModal(false)}
        onResponse={(res) => onResponse(res)}
      />
      <DeletePrompt show={isShowDeletePrompt} toggleModal={() => setIsShowDeletePrompt(false)} yes={handleDelete} />
    </>
  );
}
