import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CSpinner } from '@coreui/react';
import InputImage from '../../../component/InputImage/InputImage';
import {
  addFounderService,
  updateFounderService,
} from '../../../services/founder';

export default function AddEditFounder(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    position_title: '',
    position_title_en: '',
    name: '',
    photo: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = '';
    if (name === 'photo') {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (
      formData.name &&
      formData.photo &&
      formData.position_title &&
      formData.position_title_en
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('position_title', formData.position_title);
    formDataObj.append('position_title_en', formData.position_title_en);
    typeof formData.photo !== 'string' &&
      formDataObj.append('photo', formData.photo);
    formDataObj.append('name', formData.name);
    // formDataObj.append('kategori_en', formData.kategori_en);

    try {
      const response = isEdit
        ? await updateFounderService(formDataObj, formData.id)
        : await addFounderService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        position_title: '',
        position_title_en: '',
        name: '',
        photo: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Founder' : 'Add Founder'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <InputImage
          imgSrc={formData?.photo ? formData.photo : ''}
          name="photo"
          eclipse="true"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="name"
          title="Name"
          value={formData?.name ? formData.name : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="position_title"
          title="Position Title"
          value={formData?.position_title ? formData.position_title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="position_title_en"
          title="Position Title En"
          value={formData?.position_title_en ? formData.position_title_en : ''}
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          size="sm"
          style={{ minWidth: 125 }}
          color="primary"
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
