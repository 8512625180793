import { useEffect, useState } from 'react';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react';
import { getRequest } from '../../../services/solution';

function RequestDemo(props) {
  const [requestList, setRequestList] = useState([]);

  const getRequestList = async () => {
    try {
      const response = await getRequest(props.data?.id);
      setRequestList(response.data);
    } catch (error) {
      setRequestList([]);
      alert(error);
    }
  };

  useEffect(() => {
    getRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <AppModal
      title="Request Demo"
      visible={props.show}
      closeModal={() => {
        props.toggleModal();
      }}
    >
      <div>
        <CTable bordered responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell scope="col">No.</CTableHeaderCell>
              <CTableHeaderCell scope="col">Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Email</CTableHeaderCell>
              <CTableHeaderCell scope="col">Contact No</CTableHeaderCell>
              <CTableHeaderCell scope="col">Company Name</CTableHeaderCell>
              <CTableHeaderCell scope="col">Message</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {requestList.map((d, i) => {
              return (
                <CTableRow key={i}>
                  <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                  <CTableDataCell>{d.name}</CTableDataCell>
                  <CTableDataCell>{d.email}</CTableDataCell>
                  <CTableDataCell>{d.contact_no}</CTableDataCell>
                  <CTableDataCell>{d.company_name}</CTableDataCell>
                  <CTableDataCell>{d.message}</CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </div>
      <></>
    </AppModal>
  );
}

export default RequestDemo;
