import { useState, useEffect } from 'react';
import AppModal from '../../../component/CoreLayout/AppModal';
import FormControl from '../../../component/FormControl/FormControl';
import { CButton, CFormTextarea, CSpinner } from '@coreui/react';
import InputImage from '../../../component/InputImage/InputImage';
import FeatureList from './FeatureList';
import SBSList from './SBSList';
import {
  addSBS,
  addSolutionDetail,
  deleteFeature,
  deleteSBS,
  getFeatures,
  getSBS,
  updateSBS,
  updateSolutionDetail,
} from '../../../services/solution';
import AddEditFeatures from './AddEditFeatures';
import AddEditSBSContent from './AddEditSBSContent';
import ReactQuill from 'react-quill';

export default function AddEditDetail(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitting2, setIsSubmitting2] = useState(false);
  const [isShowFeaturesModal, setIsShowFeaturesModal] = useState(false);
  const [isShowSBSModal, setIsShowSBSModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [features, setFeatures] = useState([]);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [sbs, setSBS] = useState({
    id: '',
    product_solution_detail_id: '',
    title: '',
    title_en: '',
    items: [],
  });
  const [sbsItems, setSBSItems] = useState([]);
  const [isFeatureEdit, setIsFeatureEdit] = useState(false);
  const [isSBSEdit, setIsSBSEdit] = useState(false);
  const [selectedFeatureData, setSelectedFeatureData] = useState({});
  const [selectedSBSData, setSelectedSBSData] = useState({});
  const [formData, setFormData] = useState({
    bg_image: '',
    title: '',
    title_en: '',
    description: '',
    description_en: '',

    intro: '',
    intro_en: '',
    sub_intro: '',
    sub_intro_en: '',

    secondary_banner_title: '',
    secondary_banner_title_en: '',
    secondary_banner_description: '',
    secondary_banner_description_en: '',
    secondary_banner_bg_image: '',
    secondary_banner_side_image: '',

    isShowFeaturesDescription: '1',
  });

  useEffect(() => {
    setFormData(props.isDetailEdit ? { ...props.data } : { ...formData, id: props.data?.id });
    setIsEdit(props.isDetailEdit ? true : false);
    if (props.isDetailEdit) {
      getFeaturesList();
      getSBSData();
    } else {
      onReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onChange = (event) => {
    const name = event.target.name;
    let value;

    if (name === 'bg_image' || name === 'secondary_banner_bg_image' || name === 'secondary_banner_side_image') {
      value = event.target.files[0];
    } else if (name === 'isShowFeaturesDescription') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onReset = () => {
    setFormData({
      bg_image: '',
      title: '',
      title_en: '',
      description: '',
      description_en: '',

      intro: '',
      intro_en: '',
      sub_intro: '',
      sub_intro_en: '',

      secondary_banner_title: '',
      secondary_banner_title_en: '',
      secondary_banner_description: '',
      secondary_banner_description_en: '',
      secondary_banner_bg_image: '',
      secondary_banner_side_image: '',

      isShowFeaturesDescription: '1',
    });
  };

  const saveSBS = async () => {
    setIsSubmitting2(true);

    try {
      let response;

      if (isEdit) {
        if (sbs.id) {
          response = await updateSBS({
            id: sbs.id,
            title: sbs.title,
            title_en: sbs.title_en,
          });
        } else {
          response = await addSBS({
            product_solution_detail_id: props.data?.id,
            title: sbs.title,
            title_en: sbs.title_en,
          });
        }
      } else {
        response = await addSBS({
          product_solution_detail_id: props.data?.id,
          title: sbs.title,
          title_en: sbs.title_en,
        });
      }

      props.changeDetailData(response);
    } catch (error) {
      props.changeDetailData(error);
    } finally {
      setIsSubmitting2(false);
      setSBS({
        id: '',
        product_solution_detail_id: '',
        title: '',
        title_en: '',
        items: [],
      });
      setSBSItems([]);

      props.toggleModal();
    }
  };

  const checkInput = () => {
    if (
      formData.title &&
      formData.title_en &&
      formData.description &&
      formData.description_en &&
      formData.intro &&
      formData.intro_en &&
      formData.sub_intro &&
      formData.sub_intro_en
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveDetail = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsSubmitting(true);
    setIsAnyEmpty(false);

    const formDataObj = new FormData();
    formDataObj.append(isEdit ? 'id' : 'id_product_solution', props?.data?.id);
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);

    if (formData.bg_image) {
      typeof formData.bg_image !== 'string' && formDataObj.append('bg_image', formData.bg_image ?? '');
    }

    formDataObj.append('intro', formData.intro);
    formDataObj.append('intro_en', formData.intro_en);
    formDataObj.append('sub_intro', formData.sub_intro);
    formDataObj.append('sub_intro_en', formData.sub_intro_en);
    // formDataObj.append('isShowFeaturesDescription', formData.isShowFeaturesDescription ? '1' : '0');
    // formDataObj.append('isShowFeaturesDescription', '1');

    formDataObj.append('secondary_banner_title', formData.secondary_banner_title);
    formDataObj.append('secondary_banner_title_en', formData.secondary_banner_title_en);
    formDataObj.append('secondary_banner_description', formData.secondary_banner_description);
    formDataObj.append('secondary_banner_description_en', formData.secondary_banner_description_en);

    if (formData.secondary_banner_bg_image) {
      typeof formData.secondary_banner_bg_image !== 'string' &&
        formDataObj.append('secondary_banner_bg_image', formData.secondary_banner_bg_image ?? '');
    }

    if (formData.secondary_banner_side_image) {
      typeof formData.secondary_banner_side_image !== 'string' &&
        formDataObj.append('secondary_banner_side_image', formData.secondary_banner_side_image ?? '');
    }

    try {
      const response = isEdit ? await updateSolutionDetail(formDataObj) : await addSolutionDetail(formDataObj);
      if (!isEdit) {
        await addSBS({
          product_solution_detail_id: response.data.id,
          title: '',
          title_en: '',
        });
      }
      props.changeDetailData(response);
    } catch (error) {
      props.changeDetailData(error);
    } finally {
      setIsSubmitting(false);
      onReset();

      props.toggleModal();
    }
  };

  const getFeaturesList = async () => {
    try {
      const response = await getFeatures(props.data?.id);
      setFeatures(response.data);
    } catch (error) {
      setFeatures([]);
      alert(error);
    }
  };

  const getSBSData = async () => {
    try {
      const response = await getSBS(props.data?.id);
      if (response.data.hasOwnProperty('id')) {
        setSBS(response.data);
        setSBSItems(response.data.items);
      }
    } catch (error) {
      setSBS({
        id: '',
        product_solution_detail_id: '',
        title: '',
        title_en: '',
        items: [],
      });
      setSBSItems([]);
      alert(error);
    }
  };

  const onDeleteFeature = async (id) => {
    try {
      await deleteFeature(id);
      getFeaturesList();
    } catch (error) {
      alert(error);
    }
  };

  const onDeleteSBS = async (id) => {
    try {
      await deleteSBS(id);
      getSBSData();
    } catch (error) {
      alert(error);
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Detail' : 'Add Detail'}
      visible={props.show}
      closeModal={() => {
        props.toggleModal();
        setIsAnyEmpty(false);
      }}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <div className="card mb-4">
          <div className="card-body">
            <h4>Main Banner</h4>
            <label className="form-label">Image</label>
            <span className="required">*</span>
            <InputImage
              imgSrc={formData?.bg_image ? formData.bg_image : ''}
              name="bg_image"
              changeInputImage={onChange}
            />
            <FormControl
              type="text"
              label="title"
              title="Title"
              value={formData?.title ? formData.title : ''}
              handlerChange={onChange}
              required={true}
            />
            <FormControl
              type="text"
              label="title_en"
              title="Title En"
              value={formData?.title_en ? formData.title_en : ''}
              handlerChange={onChange}
              required={true}
            />
            <CFormTextarea
              className="mb-2"
              label="Description"
              rows="4"
              name="description"
              value={formData?.description ? formData.description : ''}
              onChange={onChange}
              required={true}
            ></CFormTextarea>
            <CFormTextarea
              className="mb-2"
              label="Description En"
              rows="4"
              name="description_en"
              value={formData?.description_en ? formData.description_en : ''}
              onChange={onChange}
              required={true}
            ></CFormTextarea>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-body">
            <h4>Introduction</h4>
            <div className="form-control-wrapper">
              <label className="form-label" htmlFor="intro">
                Introduction
              </label>
              <span className="required">*</span>
              <input
                className="form-control"
                type="text"
                name="intro"
                value={formData?.intro ? formData.intro : ''}
                onChange={onChange}
              />
            </div>
            <div className="form-control-wrapper">
              <label className="form-label" htmlFor="intro_en">
                Introduction En
              </label>
              <span className="required">*</span>
              <input
                className="form-control"
                type="text"
                name="intro_en"
                value={formData?.intro_en ? formData.intro_en : ''}
                onChange={onChange}
              />
            </div>

            <label className="form-label" htmlFor="intro_en">
              Sub Introduction
            </label>
            <span className="required">*</span>
            <CFormTextarea
              className="mb-2"
              rows="4"
              name="sub_intro"
              value={formData?.sub_intro ? formData.sub_intro : ''}
              onChange={onChange}
              required={true}
            ></CFormTextarea>
            <label className="form-label" htmlFor="intro_en">
              Sub Introduction En
            </label>
            <span className="required">*</span>
            <CFormTextarea
              className="mb-2"
              rows="4"
              name="sub_intro_en"
              value={formData?.sub_intro_en ? formData.sub_intro_en : ''}
              onChange={onChange}
              required={true}
            ></CFormTextarea>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-body">
            <h4>Secondary Banner</h4>
            <label className="form-label">Background Image</label>
            <InputImage
              imgSrc={formData?.secondary_banner_bg_image ? formData.secondary_banner_bg_image : ''}
              name="secondary_banner_bg_image"
              changeInputImage={onChange}
            />
            <label className="form-label">Side Image</label>
            <InputImage
              imgSrc={formData?.secondary_banner_side_image ? formData.secondary_banner_side_image : ''}
              name="secondary_banner_side_image"
              changeInputImage={onChange}
            />

            <FormControl
              type="text"
              label="secondary_banner_title"
              title="Title"
              value={formData?.secondary_banner_title ? formData.secondary_banner_title : ''}
              handlerChange={onChange}
            />
            <FormControl
              type="text"
              label="secondary_banner_title_en"
              title="Title En"
              value={formData?.secondary_banner_title_en ? formData.secondary_banner_title_en : ''}
              handlerChange={onChange}
            />
            <label className="form-label">Description</label>
            <ReactQuill
              value={formData?.secondary_banner_description ? formData.secondary_banner_description : ''}
              theme="snow"
              onChange={(value) => setFormData({ ...formData, secondary_banner_description: value })}
            />
            <br />
            <label className="form-label">Description En</label>
            <ReactQuill
              value={formData?.secondary_banner_description_en ? formData.secondary_banner_description_en : ''}
              theme="snow"
              onChange={(value) => setFormData({ ...formData, secondary_banner_description_en: value })}
            />
          </div>
        </div>
        <CButton style={{ minWidth: 125 }} size="sm" color="primary" disabled={isSubmitting} onClick={saveDetail}>
          {isSubmitting ? <CSpinner size="sm" /> : isEdit ? 'Edit' : 'Add'}
        </CButton>

        <hr />

        {isEdit ? (
          <>
            <div className="card mb-4">
              <div className="card-body">
                <h4>Features</h4>
                <div className="text-right">
                  <CButton
                    size="sm"
                    variant="outline"
                    color="primary"
                    onClick={() => {
                      setIsFeatureEdit(false);
                      setIsShowFeaturesModal(true);
                    }}
                  >
                    Add Features
                  </CButton>
                </div>
                <FeatureList
                  list={features}
                  onEditDetail={(data) => {
                    setIsFeatureEdit(true);
                    setSelectedFeatureData(data);
                    setIsShowFeaturesModal(true);
                  }}
                  onDeleteDetail={(data) => {
                    onDeleteFeature(data.id);
                  }}
                />
                <AddEditFeatures
                  isEdit={isFeatureEdit}
                  data={selectedFeatureData}
                  id={formData.id}
                  show={isShowFeaturesModal}
                  toggleModal={() => setIsShowFeaturesModal(false)}
                  changeDetailData={() => getFeaturesList()}
                />
              </div>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <h4>Side By Side Content</h4>
                <div className="form-control-wrapper">
                  <label className="form-label">Title</label>
                  <input
                    className="form-control"
                    type="text"
                    name="title_sbs"
                    value={sbs.title}
                    onChange={(e) => setSBS({ ...sbs, title: e.target.value })}
                  />
                </div>
                <div className="form-control-wrapper">
                  <label className="form-label">Title EN</label>
                  <input
                    className="form-control"
                    type="text"
                    name="title_sbs_en"
                    value={sbs.title_en}
                    onChange={(e) => setSBS({ ...sbs, title_en: e.target.value })}
                  />
                </div>
                <CButton style={{ minWidth: 125 }} size="sm" color="primary" onClick={saveSBS}>
                  {isSubmitting2 ? <CSpinner size="sm" /> : isEdit ? 'Edit' : 'Add'}
                </CButton>
                <div className="text-right">
                  <CButton
                    size="sm"
                    variant="outline"
                    color="primary"
                    onClick={() => {
                      setSelectedSBSData({});
                      setIsSBSEdit(false);
                      setIsShowSBSModal(true);
                    }}
                  >
                    Add Content
                  </CButton>
                </div>
                <SBSList
                  list={sbsItems}
                  onEditDetail={(data) => {
                    setIsSBSEdit(true);
                    setSelectedSBSData(data);
                    setIsShowSBSModal(true);
                  }}
                  onDeleteDetail={(data) => {
                    onDeleteSBS(data.id);
                  }}
                />
                <AddEditSBSContent
                  isEdit={isSBSEdit}
                  data={selectedSBSData}
                  id={selectedSBSData.id || sbs.id}
                  show={isShowSBSModal}
                  toggleModal={() => setIsShowSBSModal(false)}
                  changeDetailData={() => getSBSData()}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      <>
        <CButton
          size="sm"
          color="secondary"
          onClick={() => {
            props.toggleModal();
            setIsAnyEmpty(false);
          }}
        >
          Cancel
        </CButton>
      </>
    </AppModal>
  );
}
