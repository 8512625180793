import axiosConfig from './axios';

export const getFounderListService = async () => {
  try {
    const response = await axiosConfig.get('/about/founder');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addFounderService = async (data) => {
  try {
    const response = await axiosConfig.post('/about/founder', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateFounderService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/about/founder/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteFounderService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/about/founder/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
