import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import InputImage from '../../../component/InputImage/InputImage';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CSpinner, CFormTextarea } from '@coreui/react';
import {
  addCareerBannerService,
  updateCareerBannerService,
} from '../../../services/career-banner';

export default function AddEditCareerBanner(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    description: '',
    description_en: '',
    bg_image: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    const value =
      name === 'bg_image' ? event.target.files[0] : event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (
      formData.title &&
      formData.title_en &&
      formData.description &&
      formData.description_en &&
      formData.bg_image
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);
    typeof formData.bg_image !== 'string' &&
      formDataObj.append('bg_image', formData.bg_image);

    try {
      const response = isEdit
        ? await updateCareerBannerService(formDataObj, formData.id)
        : await addCareerBannerService(formDataObj);
      props.onResponse(response);
      setFormData({
        title: '',
        title_en: '',
        description: '',
        description_en: '',
        bg_image: '',
      });
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        title: '',
        title_en: '',
        description: '',
        description_en: '',
        bg_image: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Career Banner' : 'Add Career Banner'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Banner Image</label>
        <InputImage
          imgSrc={formData?.bg_image ? formData.bg_image : ''}
          name="bg_image"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Description"
          rows="2"
          name="description"
          value={formData?.description ? formData.description : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Description En"
          rows="2"
          name="description_en"
          value={formData?.description_en ? formData.description_en : ''}
          onChange={onChange}
        ></CFormTextarea>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          size="sm"
          style={{ minWidth: 125 }}
          color="primary"
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
