import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import AppModal from '../../../component/CoreLayout/AppModal';
import {
  CButton,
  CFormCheck,
  CFormSelect,
  CSpinner,
  CFormTextarea,
} from '@coreui/react';
import {
  addTestimonialService,
  updateTestimonialService,
} from '../../../services/testimonial';
import InputImage from '../../../component/InputImage/InputImage';

export default function AddEditTestimonial(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    testimonial_word: '',
    testimonial_word_en: '',
    photo: '',
    position: '',
    position_en: '',
    add_to_home: false,
    kategori: '',
    // kategori_en: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = '';
    if (name === 'photo') {
      value = event.target.files[0];
    } else if (name === 'add_to_home') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (
      formData.name &&
      formData.testimonial_word &&
      formData.testimonial_word_en &&
      formData.photo &&
      formData.position &&
      formData.position_en &&
      formData.kategori
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    formDataObj.append('testimonial_word', formData.testimonial_word);
    formDataObj.append('testimonial_word_en', formData.testimonial_word_en);
    typeof formData.photo !== 'string' &&
      formDataObj.append('photo', formData.photo);
    formDataObj.append('add_to_home', formData.add_to_home ? true : false);
    formDataObj.append('position', formData.position);
    formDataObj.append('position_en', formData.position_en);
    formDataObj.append('kategori', formData.kategori);
    // formDataObj.append('kategori_en', formData.kategori_en);

    try {
      const response = isEdit
        ? await updateTestimonialService(formDataObj, formData.id)
        : await addTestimonialService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        name: '',
        testimonial_word: '',
        testimonial_word_en: '',
        photo: '',
        position: '',
        position_en: '',
        add_to_home: false,
        kategori: '',
        // kategori_en: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Testimoni' : 'Add Testimoni'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <InputImage
          imgSrc={formData?.photo ? formData.photo : ''}
          name="photo"
          eclipse="true"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="name"
          title="Name"
          value={formData?.name ? formData.name : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Testimonial Word"
          rows="2"
          name="testimonial_word"
          value={formData?.testimonial_word ? formData.testimonial_word : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Testimonial Word En"
          rows="2"
          name="testimonial_word_en"
          value={
            formData?.testimonial_word_en ? formData.testimonial_word_en : ''
          }
          onChange={onChange}
        ></CFormTextarea>
        <FormControl
          type="text"
          label="position"
          title="Position"
          value={formData?.position ? formData.position : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="position_en"
          title="Position En"
          value={formData?.position_en ? formData.position_en : ''}
          handlerChange={onChange}
        />
        <div className="mb-2">
          <label className="form-label">Category</label>
          <CFormSelect
            name="kategori"
            value={formData?.kategori ? formData.kategori : ''}
            aria-label="Default select example"
            options={[
              'Select Category',
              { label: 'Bootcamp', value: 'bootcamp' },
              { label: 'Karyawan', value: 'karyawan' },
              { label: 'Partner', value: 'partner' },
              { label: 'Klien', value: 'klien' },
            ]}
            onChange={onChange}
          />
        </div>
        <CFormCheck
          className="my-2"
          checked={
            formData.add_to_home === 'false' || !formData.add_to_home
              ? false
              : true
          }
          id="add_to_home"
          name="add_to_home"
          label="Add to Home"
          onChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          style={{ minWidth: 125 }}
          size="sm"
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
