import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CFormSelect, CSpinner } from '@coreui/react';
import { addJobService, updateJobService } from '../../../services/job';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function AddEditOpenJob(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    role_name: '',
    role_name_en: '',
    department: '',
    department_en: '',
    job_type: '',
    job_type_en: '',
    job_desc: '',
    job_desc_en: '',
    job_requirement: '',
    job_requirement_en: '',
    experience_year: '',
    expired_date: '',
    link_for_fast_apply: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onChangeDesc = (value) => {
    setFormData({ ...formData, job_desc: value });
  };

  const onChangeDescEn = (value) => {
    setFormData({ ...formData, job_desc_en: value });
  };

  const onChangeReq = (value) => {
    setFormData({ ...formData, job_requirement: value });
  };

  const onChangeReqEn = (value) => {
    setFormData({ ...formData, job_requirement_en: value });
  };

  const checkInput = () => {
    if (
      formData.role_name &&
      formData.role_name_en &&
      formData.department &&
      formData.job_type &&
      formData.job_desc &&
      formData.job_desc_en &&
      formData.job_requirement &&
      formData.job_requirement_en &&
      formData.experience_year &&
      formData.expired_date &&
      formData.link_for_fast_apply
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('role_name', formData.role_name);
    formDataObj.append('role_name_en', formData.role_name_en);
    formDataObj.append('department', formData.department);
    formDataObj.append('department_en', formData.department_en);
    formDataObj.append('job_type', formData.job_type);
    formDataObj.append('job_type_en', formData.job_type_en);
    formDataObj.append('job_desc', formData.job_desc);
    formDataObj.append('job_desc_en', formData.job_desc_en);
    formDataObj.append('job_requirement', formData.job_requirement);
    formDataObj.append('job_requirement_en', formData.job_requirement_en);
    formDataObj.append('experience_year', formData.experience_year);
    formDataObj.append('expired_date', formData.expired_date);
    formDataObj.append('link_for_fast_apply', formData.link_for_fast_apply);

    try {
      const response = isEdit
        ? await updateJobService(formDataObj, formData.id)
        : await addJobService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        role_name: '',
        role_name_en: '',
        department: '',
        department_en: '',
        job_type: '',
        job_type_en: '',
        job_desc: '',
        job_desc_en: '',
        job_requirement: '',
        job_requirement_en: '',
        experience_year: '',
        expired_date: '',
        link_for_fast_apply: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Job' : 'Add Job'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <FormControl
          type="text"
          label="role_name"
          title="Role Name"
          value={formData?.role_name ? formData.role_name : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="role_name_en"
          title="Role Name En"
          value={formData?.role_name_en ? formData.role_name_en : ''}
          handlerChange={onChange}
        />
        <div className="mb-2">
          <label className="form-label">Department</label>
          <CFormSelect
            name="department"
            value={formData?.department ? formData.department : ''}
            options={[
              'Select Department',
              { label: 'Tech', value: 'Tech' },
              { label: 'People', value: 'People' },
              {
                label: 'Finance, Accounting & Tax',
                value: 'Finance, Accounting & Tax',
              },
              { label: 'Legal', value: 'Legal' },
              { label: 'Business Development', value: 'Business Development' },
              { label: 'Brand & Creative', value: 'Brand & Creative' },
            ]}
            onChange={onChange}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Job Type</label>
          <CFormSelect
            name="job_type"
            value={formData?.job_type ? formData.job_type : ''}
            options={[
              'Select Job Type',
              { label: 'Full Time', value: 'Full Time' },
              { label: 'Internship', value: 'Internship' },
            ]}
            onChange={onChange}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Job Description</label>
          <ReactQuill
            value={formData?.job_desc ? formData.job_desc : ''}
            theme="snow"
            onChange={onChangeDesc}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Job Description En</label>
          <ReactQuill
            value={formData?.job_desc_en ? formData.job_desc_en : ''}
            theme="snow"
            onChange={onChangeDescEn}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Job Requirement</label>
          <ReactQuill
            value={formData?.job_requirement ? formData.job_requirement : ''}
            theme="snow"
            onChange={onChangeReq}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Job Requirement En</label>
          <ReactQuill
            value={
              formData?.job_requirement_en ? formData.job_requirement_en : ''
            }
            theme="snow"
            onChange={onChangeReqEn}
          />
        </div>
        <FormControl
          type="text"
          label="experience_year"
          title="Experience Year"
          value={formData?.experience_year ? formData.experience_year : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="date"
          label="expired_date"
          title="Expired Date"
          value={formData?.expired_date ? formData.expired_date : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="link_for_fast_apply"
          title="Link For Fast Apply"
          value={
            formData?.link_for_fast_apply ? formData.link_for_fast_apply : ''
          }
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          size="sm"
          style={{ minWidth: 125 }}
          color="primary"
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
