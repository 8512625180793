import AppModal from '../CoreLayout/AppModal';
import { CButton } from '@coreui/react';

export default function DeletePrompt(props) {
  return (
    <AppModal size="sm" visible={props.show} closeModal={props.toggleModal}>
      <p>Are you sure to delete this data?</p>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          No
        </CButton>
        <CButton size="sm" color="primary" onClick={props.yes}>
          Yes
        </CButton>
      </>
    </AppModal>
  );
}
