import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CFormSelect, CSpinner } from '@coreui/react';
import InputImage from '../../../component/InputImage/InputImage';
import { addClientService, getCategoryListService, updateClientService } from '../../../services/client';

export default function AddEditClient(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    image: '',
    category: '',
  });

  const getCategoryList = async () => {
    const response = await getCategoryListService();
    setCategoryList(response.data);
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = '';
    if (name === 'image') {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (formData.name && formData.image && formData.category) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    typeof formData.image !== 'string' && formDataObj.append('image', formData.image);
    formDataObj.append('name', formData.name);
    formDataObj.append('category', formData.category);

    try {
      const response = isEdit
        ? await updateClientService(formDataObj, formData.id)
        : await addClientService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        name: '',
        image: '',
        category: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Client' : 'Add Client'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <InputImage
          imgSrc={formData?.image ? formData.image : ''}
          name="image"
          eclipse="true"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="name"
          title="Name"
          value={formData?.name ? formData.name : ''}
          handlerChange={onChange}
        />
        <div className="mb-2">
          <label className="form-label">Category</label>
          <CFormSelect
            name="category"
            value={formData?.category ? formData.category : ''}
            options={[
              'Select Category',
              ...categoryList.map((cl) => {
                return {
                  label: cl.name,
                  value: cl.id,
                };
              }),
            ]}
            onChange={onChange}
          />
        </div>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton size="sm" style={{ minWidth: 125 }} color="primary" onClick={handleSubmit}>
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
