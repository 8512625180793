import { useState, useEffect } from 'react';
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CSpinner,
  CFormTextarea,
} from '@coreui/react';
import { cilPencil, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AppModal from '../../../component/CoreLayout/AppModal';
import FormControl from '../../../component/FormControl/FormControl';
import {
  addProductExperienceService,
  updateProductExperienceService,
} from '../../../services/experience';
import AddEditSector from './AddEditSector';
import InputImage from '../../../component/InputImage/InputImage';

export default function AddEditExperience(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [isShowSectorModal, setIsShowSectorModal] = useState(false);
  const [selectedSectorData, setSelectedSectorData] = useState(null);
  const [selectedSectorIndex, setSelectedSectorIndex] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    description: '',
    description_en: '',
    sector: [],
    bg_image: '',
  });

  useEffect(() => {
    props.data && setFormData(props.data);
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value;
    if (name === 'bg_image') {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const changeSectorData = (data) => {
    const newData = { ...data };
    setIsShowSectorModal(false);
    if (selectedSectorIndex !== null) {
      const copyData = { ...formData };
      copyData.sector[selectedSectorIndex] = newData;
      setFormData(copyData);
      setSelectedSectorData(null);
      setSelectedSectorIndex(null);
    } else {
      const copyData = { ...formData };
      copyData.sector.push(newData);
      setFormData(copyData);
      setSelectedSectorData(null);
    }
  };

  const onEditSector = (data) => {
    setIsShowSectorModal(true);
    setSelectedSectorData(data);
  };

  const onDeleteSector = (index) => {
    const copyFormData = { ...formData };
    copyFormData.sector.splice(index, 1);
    setFormData({
      ...formData,
      copyFormData,
    });
  };

  const checkInput = () => {
    if (
      formData.title &&
      formData.title_en &&
      formData.description &&
      formData.description_en &&
      formData.sector.length &&
      formData.bg_image
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);
    typeof formData.bg_image !== 'string' &&
      formDataObj.append('bg_image', formData.bg_image);

    for (var i = 0; i < formData.sector.length; i++) {
      formDataObj.append(`sector[${i}][name]`, formData.sector[i].name);
      formDataObj.append(
        `sector[${i}][description]`,
        formData.sector[i].description
      );
      formDataObj.append(
        `sector[${i}][description_en]`,
        formData.sector[i].description_en
      );
    }

    try {
      const response = isEdit
        ? await updateProductExperienceService(formDataObj, formData.id)
        : await addProductExperienceService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        title: '',
        title_en: '',
        description: '',
        description_en: '',
        sector: [],
        bg_image: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Experience' : 'Add Experience'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Background Image</label>
        <InputImage
          imgSrc={formData?.bg_image ? formData.bg_image : ''}
          name="bg_image"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Description"
          rows="6"
          name="description"
          value={formData?.description ? formData.description : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Description En"
          rows="6"
          name="description_en"
          value={formData?.description_en ? formData.description_en : ''}
          onChange={onChange}
        ></CFormTextarea>
        <div className="mt-4">
          <label className="form-label">Sectors</label>
          <CButton
            size="sm"
            variant="outline"
            className="float-end"
            color="primary"
            onClick={() => {
              setSelectedSectorIndex(null);
              setSelectedSectorData(null);
              setIsShowSectorModal(true);
            }}
          >
            Add Sector
          </CButton>
          <div className="mt-2">
            <CTable bordered responsive>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {formData?.sector?.map((d, i) => {
                  return (
                    <CTableRow key={i}>
                      <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                      <CTableDataCell>{d.name}</CTableDataCell>
                      <CTableDataCell>
                        <div className="table-action-wrapper">
                          <button className="btn-edit">
                            <CIcon
                              icon={cilPencil}
                              onClick={() => {
                                onEditSector(d);
                                setSelectedSectorIndex(i);
                              }}
                            />
                          </button>
                          <button className="btn-delete">
                            <CIcon
                              icon={cilTrash}
                              onClick={() => onDeleteSector(i)}
                            />
                          </button>
                        </div>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
              </CTableBody>
            </CTable>
            <AddEditSector
              data={selectedSectorData}
              show={isShowSectorModal}
              toggleModal={() => setIsShowSectorModal(false)}
              changeSectorData={changeSectorData}
            />
          </div>
        </div>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          style={{ minWidth: 125 }}
          size="sm"
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
