import axiosConfig from './axios';

export const getCaseStudyListService = async () => {
  try {
    const response = await axiosConfig.get('/home/study');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addCaseStudyService = async (data) => {
  try {
    const response = await axiosConfig.post('/home/study', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateCaseStudyService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/home/study/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteCaseStudyService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/home/study/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
