import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import InputImage from '../../../component/InputImage/InputImage';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CFormTextarea, CSpinner } from '@coreui/react';
import {
  addProductBannerService,
  updateProductBannerService,
} from '../../../services/product-banner';

export default function AddEditProductBanner(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    description: '',
    description_en: '',
    bg_image: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = '';
    if (name === 'bg_image') {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (
      formData.title &&
      formData.title_en &&
      formData.description &&
      formData.description_en &&
      formData.bg_image
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);
    typeof formData.bg_image !== 'string' &&
      formDataObj.append('bg_image', formData.bg_image);

    try {
      const response = isEdit
        ? await updateProductBannerService(formDataObj, formData.id)
        : await addProductBannerService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        title: '',
        title_en: '',
        description: '',
        description_en: '',
        bg_image: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Product Banner' : 'Add Product Banner'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Background Image</label>
        <InputImage
          imgSrc={formData?.bg_image ? formData.bg_image : ''}
          name="bg_image"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Description"
          rows="2"
          name="description"
          value={formData?.description ? formData.description : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Description En"
          rows="2"
          name="description_en"
          value={formData?.description_en ? formData.description_en : ''}
          onChange={onChange}
        ></CFormTextarea>
        {/* <FormControl
          type="text"
          label="button_name"
          title="Button Name"
          value={formData?.button_name ? formData.button_name : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="button_url"
          title="Button URL"
          value={formData?.button_url ? formData.button_url : ''}
          handlerChange={onChange}
        />
        <CFormCheck
          className="my-2"
          checked={!!formData.button_is_show}
          id="button_is_show"
          name="button_is_show"
          label="Button is show"
          onChange={onChange}
        /> */}
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          style={{ minWidth: 125 }}
          size="sm"
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
