import { useState, useEffect } from 'react';
import { getNewsListService } from '../../../services/news';
import NewsList from './NewsList';
import AddEditNews from './AddEditNews';
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import Alert from '../../../component/Alert';

export default function News() {
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [list, setList] = useState(null);
  const [pageOfItems, setPageOfItems] = useState([]);

  const getList = async () => {
    const response = await getNewsListService();
    // setList(response?.data);
    setList(response.data.map((d, i) => ({ index: i + 1, ...d })));
    if (response?.status !== 200) {
      setErrorMessage(response?.response?.data?.message || response?.message);
    }
  };

  const handleResponse = (res) => {
    setSuccessMessage('');
    setErrorMessage('');

    if (res.status === 200) {
      setSuccessMessage(res.message);
      getList();
    } else {
      setErrorMessage(res.response ? res.response.data.message : res.message);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>News</span>
          <CButton onClick={() => setIsShowAddModal(true)} size="sm" className="float-end" color="primary">
            Add News
          </CButton>
        </CCardHeader>
        <CCardBody>
          <NewsList
            list={list}
            onResponse={(res) => handleResponse(res)}
            setPageOfItems={setPageOfItems}
            pageOfItems={pageOfItems}
          />
        </CCardBody>
      </CCard>
      <AddEditNews
        show={isShowAddModal}
        toggleModal={() => setIsShowAddModal(false)}
        onResponse={(res) => handleResponse(res)}
      />
    </>
  );
}
