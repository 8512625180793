import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import InputImage from '../../../component/InputImage/InputImage';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CSpinner, CFormTextarea } from '@coreui/react';
import {
  addOfficeService,
  updateOfficeService,
} from '../../../services/office';

export default function AddEditOffice(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    alamat: '',
    alamat_en: '',
    phone_number: '',
    bg_image: '',
    url_link_map: '',
    potition: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    const value =
      name === 'bg_image' ? event.target.files[0] : event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (
      formData.title &&
      formData.title_en &&
      formData.alamat &&
      formData.alamat_en &&
      formData.bg_image &&
      formData.url_link_map &&
      formData.potition
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('alamat', formData.alamat);
    formDataObj.append('alamat_en', formData.alamat_en);
    formDataObj.append('phone_number', formData.phone_number);
    formDataObj.append('url_link_map', formData.url_link_map);
    formDataObj.append('potition', formData.potition);
    typeof formData.bg_image !== 'string' &&
      formDataObj.append('bg_image', formData.bg_image);

    try {
      const response = isEdit
        ? await updateOfficeService(formDataObj, formData.id)
        : await addOfficeService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        title: '',
        title_en: '',
        alamat: '',
        alamat_en: '',
        phone_number: '',
        bg_image: '',
        url_link_map: '',
        potition: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Office' : 'Add Office'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Background Image</label>
        <InputImage
          imgSrc={formData?.bg_image ? formData.bg_image : ''}
          name="bg_image"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Alamat"
          rows="2"
          name="alamat"
          value={formData?.alamat ? formData.alamat : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Alamat En"
          rows="2"
          name="alamat_en"
          value={formData?.alamat_en ? formData.alamat_en : ''}
          onChange={onChange}
        ></CFormTextarea>
        <FormControl
          type="text"
          label="phone_number"
          title="Phone / Email"
          value={formData?.phone_number ? formData.phone_number : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="url_link_map"
          title="Link Map"
          value={formData?.url_link_map ? formData.url_link_map : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="potition"
          title="Lokasi"
          value={formData?.potition ? formData.potition : ''}
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          style={{ minWidth: 125 }}
          size="sm"
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
