import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import AppModal from '../../../component/CoreLayout/AppModal';
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CSpinner,
} from '@coreui/react';
import {
  addMilestoneService,
  updateMilestoneService,
} from '../../../services/milestone';
import AddEditEvent from './Event/AddEditEvent';
import { cilPencil, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

export default function AddEditMilestone(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowEventModal, setIsShowEventModal] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState(null);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [formData, setFormData] = useState({
    year: '',
    events: [],
  });

  useEffect(() => {
    props.data && setFormData(props.data);
    // setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (formData.year && formData.events.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('year', formData.year);
    for (let i = 0; i < formData.events.length; i++) {
      for (let j = 0; j < props?.data?.events?.length; j++) {
        if (j === i && props?.data?.events[j].id) {
          formDataObj.append(`events[${j}][id]`, props?.data?.events[j].id);
        }
      }

      formDataObj.append(`events[${i}][title]`, formData.events[i].title);
      formDataObj.append(`events[${i}][title_en]`, formData.events[i].title_en);
      formDataObj.append(
        `events[${i}][description]`,
        formData.events[i].description
      );
      formDataObj.append(
        `events[${i}][description_en]`,
        formData.events[i].description_en
      );
      typeof formData.events[i].image !== 'string' &&
        formDataObj.append(`events[${i}][image]`, formData.events[i].image);
    }

    try {
      const response = isEdit
        ? await updateMilestoneService(formDataObj, formData.id)
        : await addMilestoneService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      props.toggleModal();
      setSelectedEventData(null);
      setSelectedEventIndex(null);
      setFormData({
        year: '',
        events: [],
      });
    }
  };

  const onDeleteEvent = (index) => {
    const copyFormData = { ...formData };
    copyFormData.events.splice(index, 1);
    setFormData({
      ...formData,
      copyFormData,
    });
  };

  const onEditEvent = (data) => {
    setIsShowEventModal(true);
    setSelectedEventData(data);
  };

  const changeEventData = (data) => {
    const newData = { ...data };
    setIsShowEventModal(false);
    if (selectedEventIndex !== null) {
      const copyData = { ...formData };
      copyData.events[selectedEventIndex] = newData;
      setFormData(copyData);
      setSelectedEventData(null);
      setSelectedEventIndex(null);
    } else {
      const copyData = { ...formData };
      copyData.events = formData.events;
      copyData.events.push(newData);
      setFormData(copyData);
      setSelectedEventData(null);
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Milestone' : 'Add Milestone'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <FormControl
          type="text"
          label="year"
          title="Year"
          value={formData?.year ? formData.year : ''}
          handlerChange={onChange}
        />
        <div className="mt-4">
          <label className="form-label">Events</label>
          <CButton
            size="sm"
            variant="outline"
            className="float-end"
            color="primary"
            onClick={() => {
              setSelectedEventIndex(null);
              setSelectedEventData(null);
              setIsShowEventModal(true);
            }}
          >
            Add Event
          </CButton>
          <div className="mt-2">
            <CTable bordered responsive>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Title</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {formData?.events?.map((d, i) => {
                  return (
                    <CTableRow key={i}>
                      <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                      <CTableDataCell>{d.title}</CTableDataCell>
                      <CTableDataCell>{d.description}</CTableDataCell>
                      <CTableDataCell>
                        <div className="table-action-wrapper">
                          <button className="btn-edit">
                            <CIcon
                              icon={cilPencil}
                              onClick={() => {
                                onEditEvent(d);
                                setSelectedEventIndex(i);
                              }}
                            />
                          </button>
                          <button className="btn-delete">
                            <CIcon
                              icon={cilTrash}
                              onClick={() => onDeleteEvent(i)}
                            />
                          </button>
                        </div>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
              </CTableBody>
            </CTable>
            <AddEditEvent
              data={selectedEventData}
              show={isShowEventModal}
              toggleModal={() => setIsShowEventModal(false)}
              changeEventData={changeEventData}
            />
          </div>
        </div>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          size="sm"
          style={{ minWidth: 125 }}
          color="primary"
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
