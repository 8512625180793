import axiosConfig from './axios';

export const getBannerListService = async () => {
  try {
    const response = await axiosConfig.get('/about/banner');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateBannerService = async (data) => {
  try {
    const response = await axiosConfig.post(
      `/about/banner/2?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
