import { cilPencil, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CTable, CTableHead, CTableRow, CTableHeaderCell, CTableBody, CTableDataCell } from '@coreui/react';

function SBSList({ list, onEditDetail, onDeleteDetail, setSelectedDetailIndex }) {
  return (
    <div className="mt-2">
      <CTable bordered responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">No.</CTableHeaderCell>
            <CTableHeaderCell scope="col">Title</CTableHeaderCell>
            <CTableHeaderCell scope="col">Description</CTableHeaderCell>
            <CTableHeaderCell scope="col">Action</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {!list?.length ? (
            <CTableRow>
              <CTableDataCell colSpan={4} className="text-center">
                Data Empty
              </CTableDataCell>
            </CTableRow>
          ) : (
            list?.map((d, i) => {
              return (
                <CTableRow key={i}>
                  <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                  <CTableDataCell>{d.title}</CTableDataCell>
                  <CTableDataCell>{d.description}</CTableDataCell>
                  <CTableDataCell>
                    <div className="table-action-wrapper">
                      <button className="btn-edit">
                        <CIcon
                          icon={cilPencil}
                          onClick={() => {
                            onEditDetail(d);
                          }}
                        />
                      </button>
                      <button className="btn-delete">
                        <CIcon icon={cilTrash} onClick={() => onDeleteDetail(d)} />
                      </button>
                    </div>
                  </CTableDataCell>
                </CTableRow>
              );
            })
          )}
        </CTableBody>
      </CTable>
    </div>
  );
}

export default SBSList;
