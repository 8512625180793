import axiosConfig from './axios';

export const getSolutionListService = async () => {
  try {
    const response = await axiosConfig.get('/product/solution');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addSolutionService = async (data) => {
  try {
    // const response = await axiosConfig.post('/product/solution', data);
    const response = await axiosConfig.post('/new/product/solution/create', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addSolutionDetail = async (data) => {
  try {
    const response = await axiosConfig.post('/new/product/solution/detail/create', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addSBS = async (data) => {
  try {
    const response = await axiosConfig.post('/new/product/solution/detail/sideContent/create', data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateSBS = async (data) => {
  try {
    const response = await axiosConfig.post('/new/product/solution/detail/sideContent/update', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateSolutionDetail = async (data) => {
  try {
    const response = await axiosConfig.post('/new/product/solution/detail/update', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const changeOrder = async (data) => {
  try {
    const response = await axiosConfig.post('/new/product/solution/updateOrder', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateSolutionService = async (data) => {
  try {
    // const response = await axiosConfig.post(`/product/solution/${id}?_method=PUT`, data);
    const response = await axiosConfig.post(`/new/product/solution/update`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getFeatures = async (product_solution_detail_id) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/features`, { product_solution_detail_id });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSBS = async (product_solution_detail_id) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/sideContent`, { product_solution_detail_id });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getRequest = async (product_solution_detail_id) => {
  try {
    const response = await axiosConfig.post(
      `/new/product/solution/detail/requestProductDemo/getProductDemoByDetailId`,
      { product_solution_detail_id }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addFeature = async (data) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/features/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addSBSItem = async (data) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/sideContent/items/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateSBSItem = async (data) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/sideContent/items/update`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateFeature = async (data) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/features/update`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSolutionService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/product/solution/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSolutionDetail = async (id) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/delete`, { id });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteFeature = async (id) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/features/delete`, { id });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSBS = async (id) => {
  try {
    const response = await axiosConfig.post(`/new/product/solution/detail/sideContent/items/delete`, { id });
    return response.data;
  } catch (error) {
    return error;
  }
};
