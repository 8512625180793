import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CSpinner } from '@coreui/react';
import InputImage from '../../../component/InputImage/InputImage';
import { addFeature, updateFeature } from '../../../services/solution';
import ReactQuill from 'react-quill';

function AddEditFeatures(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    icon: '',
    title: '',
    title_en: '',
    description: '',
    description_en: '',
  });

  useEffect(() => {
    setFormData(
      props.isEdit
        ? { ...props.data }
        : {
            ...{
              icon: '',
              title: '',
              title_en: '',
              description: '',
              description_en: '',
            },
            id: props?.id,
          }
    );
    setIsEdit(props.isEdit ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = name === 'icon' ? event.target.files[0] : event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onReset = () => {
    setFormData({
      id: '',
      icon: '',
      title: '',
      title_en: '',
      description: '',
      description_en: '',
    });
  };

  const saveFeature = async () => {
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append(isEdit ? 'id' : 'product_solution_detail_id', formData.id);
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);
    if (formData.icon) {
      typeof formData.icon !== 'string' && formDataObj.append('icon', formData.icon);
    }

    try {
      const response = isEdit ? await updateFeature(formDataObj) : await addFeature(formDataObj);
      props.changeDetailData(response);
      props.toggleModal();
      onReset();
    } catch (error) {
      alert(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Feature' : 'Add Feature'}
      visible={props.show}
      closeModal={() => {
        onReset();
        props.toggleModal();
      }}
    >
      <div>
        <label className="form-label">Icon</label>
        <InputImage imgSrc={formData?.icon ? formData.icon : ''} name="icon" changeInputImage={onChange} />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title EN"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <label className="form-label">Description</label>
        <ReactQuill
          value={formData?.description ? formData.description : ''}
          theme="snow"
          onChange={(value) => setFormData({ ...formData, description: value })}
        />
        <label className="form-label">Description En</label>
        <ReactQuill
          value={formData?.description_en ? formData.description_en : ''}
          theme="snow"
          onChange={(value) => setFormData({ ...formData, description_en: value })}
        />
      </div>
      <>
        <CButton
          size="sm"
          color="secondary"
          onClick={() => {
            onReset();
            props.toggleModal();
          }}
        >
          Cancel
        </CButton>
        <CButton style={{ minWidth: 125 }} size="sm" color="primary" disabled={isSubmitting} onClick={saveFeature}>
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}

export default AddEditFeatures;
