import axiosConfig from './axios';

export const getPartnerListService = async () => {
  try {
    const response = await axiosConfig.get('/about/partner');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addPartnerService = async (data) => {
  try {
    const response = await axiosConfig.post('/about/partner', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updatePartnerService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/about/partner/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deletePartnerService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/about/partner/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
