import React, { useState, useEffect } from 'react';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import DeletePrompt from '../../component/DeletePrompt';
import Alert from '../../component/Alert';
import {
  deleteBootcampService,
  getBootcampListService,
} from '../../services/bootcamp';
import JwPagination from 'jw-react-pagination';

const BootcampApplicant = () => {
  const [list, setList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [isShowDeletePrompt, setIsShowDeletePrompt] = useState(false);
  const [dataId, setDataId] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const getList = async () => {
    const response = await getBootcampListService();
    setList(response.data.map((d, i) => ({ index: i + 1, ...d })));
    if (response.status !== 200) {
      setErrorMessage(response.response.data.message || response.message);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const onDelete = (id) => {
    setDataId(id);
    setIsShowDeletePrompt(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteBootcampService(dataId);
      handleResponse(response);
    } catch (error) {
      handleResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  const handleResponse = (res) => {
    setSuccessMessage('');
    setErrorMessage('');

    if (res.status === 200) {
      setSuccessMessage(res.message);
      getList();
    } else {
      setErrorMessage(res.response ? res.response.data.message : res.message);
    }
  };

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>List Bootcamp Applicant</span>
        </CCardHeader>
        <CCardBody>
          <CTable bordered responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                <CTableHeaderCell scope="col">No Handphone</CTableHeaderCell>
                {/* <CTableHeaderCell scope="col">Position</CTableHeaderCell> */}
                <CTableHeaderCell scope="col">Resume</CTableHeaderCell>
                <CTableHeaderCell scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {pageOfItems &&
                pageOfItems.map((data) => {
                  return (
                    <CTableRow key={data.index}>
                      <CTableHeaderCell scope="row">
                        {data.index}
                      </CTableHeaderCell>
                      <CTableDataCell>{data.name}</CTableDataCell>
                      <CTableDataCell>{data.email}</CTableDataCell>
                      <CTableDataCell>{data.handphone}</CTableDataCell>
                      {/* <CTableDataCell>{data.position}</CTableDataCell> */}
                      <CTableDataCell>
                        <a
                          download
                          target="_blank"
                          href={data.cv}
                          rel="noreferrer"
                        >
                          Download Here
                        </a>
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="table-action-wrapper">
                          <button className="btn-delete">
                            <CIcon
                              icon={cilTrash}
                              onClick={() => onDelete(data.id)}
                            />
                          </button>
                        </div>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
            </CTableBody>
          </CTable>
          <JwPagination pageSize={5} items={list} onChangePage={onChangePage} />
          <DeletePrompt
            show={isShowDeletePrompt}
            toggleModal={() => setIsShowDeletePrompt(false)}
            yes={handleDelete}
          />
        </CCardBody>
      </CCard>
    </>
  );
};

export default BootcampApplicant;
