import { useState, useEffect } from 'react';
import { CButton, CSpinner } from '@coreui/react';
import { addThemeService, updateThemeService } from '../../services/theme';
import AppModal from '../../component/CoreLayout/AppModal';
import InputImage from '../../component/InputImage/InputImage';
import FormControl from '../../component/FormControl/FormControl';

export default function AddEditTheme(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    bg_header: '',
    bg_footer: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = '';
    if (name === 'bg_header' || name === 'bg_footer') {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('name', formData.name);
    typeof formData.bg_header !== 'string' &&
      formDataObj.append('bg_header', formData.bg_header);
    typeof formData.bg_footer !== 'string' &&
      formDataObj.append('bg_footer', formData.bg_footer);

    try {
      const response = isEdit
        ? await updateThemeService(formDataObj, formData.id)
        : await addThemeService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        name: '',
        bg_header: '',
        bg_footer: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Insight Banner' : 'Add Insight Banner'}
      visible={props.show}
      closeModal={props.toggleModal}
    >
      <div>
        <label className="form-label">Background Header</label>
        <InputImage
          imgSrc={formData?.bg_header ? formData.bg_header : ''}
          name="bg_header"
          changeInputImage={onChange}
        />
        <label className="form-label">Background Footer</label>
        <InputImage
          imgSrc={formData?.bg_footer ? formData.bg_footer : ''}
          name="bg_footer"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="name"
          title="Name"
          value={formData?.name ? formData.name : ''}
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          style={{ minWidth: 125 }}
          size="sm"
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
