import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react';
// import CIcon from '@coreui/icons-react'
import LogoIndocyber from '../../assets/img/logo.png';

import { AppSidebarNav } from './AppSidebarNav';

// import SimpleBar from 'simplebar-react'
// import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from './_nav';
import { toggleSidebar } from '../../Redux/SidebarSlice';

const AppSidebar = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebar.sidebarShow);

  return (
    <CSidebar position="fixed" visible={sidebarShow} onVisibleChange={() => dispatch(dispatch(toggleSidebar()))}>
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img src={LogoIndocyber} alt="Logo Indocyber" className="logo-img" />
        <span>Indocyber Global</span>
      </CSidebarBrand>
      <CSidebarNav>
        <AppSidebarNav items={navigation} />
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
