import { useState, useEffect } from 'react';
import { CButton, CSpinner, CFormTextarea } from '@coreui/react';
import { addEmployee, updateEmployee } from '../../services/employee';
import FormControl from '../../component/FormControl/FormControl';
import AppModal from '../../component/CoreLayout/AppModal';
import InputImage from '../../component/InputImage/InputImage';

export default function AddEditEmployee(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    position: '',
    position_en: '',
    email: '',
    phonenumber: '',
    phonenumber_work: '',
    address: '',
    address_en: '',
    instagram: '',
    linkedin: '',
    foto: '',
  });

  useEffect(() => {
    setFormData(props.employee ?? { ...props.employee });
    setIsEdit(props.employee ? true : false);
  }, [props.employee]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = name === 'foto' ? event.target.files[0] : event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const checkInput = () => {
    if (
      formData.firstname &&
      formData.lastname &&
      formData.position &&
      formData.position_en &&
      formData.email &&
      formData.phonenumber &&
      formData.phonenumber_work &&
      formData.address &&
      formData.address_en &&
      formData.instagram &&
      formData.linkedin &&
      formData.foto
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();

    formDataObj.append('firstname', formData.firstname);
    formDataObj.append('lastname', formData.lastname);
    formDataObj.append('position', formData.position);
    formDataObj.append('position_en', formData.position_en);
    formDataObj.append('email', formData.email);
    formDataObj.append('phonenumber', formData.phonenumber);
    formDataObj.append('phonenumber_work', formData.phonenumber_work);
    formDataObj.append('address', formData.address);
    formDataObj.append('address_en', formData.address_en);
    formDataObj.append('instagram', formData.instagram);
    formDataObj.append('linkedin', formData.linkedin);
    typeof formData.foto !== 'string' &&
      formDataObj.append('foto', formData.foto);

    try {
      const response = isEdit
        ? await updateEmployee(formDataObj, formData.id)
        : await addEmployee(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        firstname: '',
        lastname: '',
        position: '',
        position_en: '',
        email: '',
        phonenumber: '',
        phonenumber_en: '',
        address: '',
        address_en: '',
        instagram: '',
        linkedin: '',
        foto: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Employee' : 'Add Employee'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <InputImage
          imgSrc={formData?.foto ? formData.foto : ''}
          name="foto"
          eclipse="true"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="firstname"
          title="Firstname"
          value={formData?.firstname ? formData.firstname : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="lastname"
          title="Lastname"
          value={formData?.lastname ? formData.lastname : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="position"
          title="Position"
          value={formData?.position ? formData.position : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="position_en"
          title="Position En"
          value={formData?.position_en ? formData.position_en : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="email"
          title="Email"
          value={formData?.email ? formData.email : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="number"
          label="phonenumber"
          title="Phone Number"
          value={formData?.phonenumber ? formData.phonenumber : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="number"
          label="phonenumber_work"
          title="Phone Number Work"
          value={formData?.phonenumber_work ? formData.phonenumber_work : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Address"
          rows="2"
          name="address"
          value={formData?.address ? formData.address : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Address En"
          rows="2"
          name="address_en"
          value={formData?.address_en ? formData.address_en : ''}
          onChange={onChange}
        ></CFormTextarea>
        <FormControl
          type="text"
          label="instagram"
          title="Instagram"
          value={formData?.instagram ? formData.instagram : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="linkedin"
          title="Linkedin"
          value={formData?.linkedin ? formData.linkedin : ''}
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          style={{ minWidth: 125 }}
          size="sm"
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
