import axiosConfig from './axios';

export const getSummaryListService = async () => {
  try {
    const response = await axiosConfig.get('/about/summary');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addSummaryService = async (data) => {
  try {
    const response = await axiosConfig.post('/about/summary', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateSummaryService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/about/summary/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteSummaryService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/about/summary/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
