import { useState, useEffect } from 'react';
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import Alert from '../../../component/Alert';
import { getSolutionListService } from '../../../services/solution';
import SolutionList from './SolutionList';
import AddEditSolution from './AddEditSolution';

export default function Solution() {
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [list, setList] = useState(null);

  function compareByOrder(a, b) {
    return a.order - b.order;
  }

  const getList = async () => {
    const response = await getSolutionListService();
    response.data.sort(compareByOrder);
    setList(response.data);
    if (response.status !== 200) {
      setErrorMessage(response.response.data.message || response.message);
    }
  };

  const handleResponse = (res) => {
    setSuccessMessage('');
    setErrorMessage('');

    if (res.status === 200) {
      setSuccessMessage(res.message);
      getList();
    } else {
      setErrorMessage(res.response ? res.response.data.message : res.message);
    }
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>Solution</span>
          <CButton onClick={() => setIsShowAddModal(true)} size="sm" className="float-end" color="primary">
            Add Solution
          </CButton>
        </CCardHeader>
        <CCardBody>
          <SolutionList data={list} onResponse={(res) => handleResponse(res)} />
        </CCardBody>
      </CCard>
      <AddEditSolution
        show={isShowAddModal}
        toggleModal={() => setIsShowAddModal(false)}
        onResponse={(res) => handleResponse(res)}
      />
    </>
  );
}
