import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import AppModal from '../../../component/CoreLayout/AppModal';
import { cilPencil, cilTrash, cilList } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CSpinner,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react';
import {
  addSolutionService,
  updateSolutionService,
  changeOrder,
  deleteSolutionDetail,
} from '../../../services/solution';
import AddEditDetail from './AddEditDetail';
import RequestDemo from './RequestDemo';

export default function AddEditSolution(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDetailEdit, setIsDetailEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [isShowDetailModal, setIsShowDetailModal] = useState(false);
  const [isShowRequestModal, setIsShowRequestModal] = useState(false);
  const [selectedDetailData, setSelectedDetailData] = useState(null);
  const [selectedDetailIndex, setSelectedDetailIndex] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    order: '',
  });

  useEffect(() => {
    props.data && setFormData(props.data);
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = name === 'image' ? event.target.files[0] : event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const changeDetailData = (response) => {
    // const newData = { ...data };
    // setIsShowDetailModal(false);
    // if (selectedDetailIndex !== null) {
    //   const copyData = { ...formData };
    //   copyData.detail[selectedDetailIndex] = newData;
    //   setFormData(copyData);
    //   setSelectedDetailData(null);
    //   setSelectedDetailIndex(null);
    // } else {
    //   const copyData = { ...formData };
    //   copyData.detail = formData.detail;
    //   copyData.detail.push(newData);
    //   setFormData(copyData);
    //   setSelectedDetailData(null);
    // }
    props.onResponse(response);
    props.toggleModal();
  };

  const onEditDetail = (data) => {
    setIsShowDetailModal(true);
    setSelectedDetailData(data);
    setIsDetailEdit(true);
  };

  const onDeleteDetail = async (id) => {
    // const copyFormData = { ...formData };
    // copyFormData.detail.splice(index, 1);
    // setFormData({
    //   ...formData,
    //   copyFormData,
    // });

    setIsDeleting(true);
    try {
      const response = await deleteSolutionDetail(id);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsDeleting(false);
      props.toggleModal();
    }
  };

  const checkInput = () => {
    if (formData.title && formData.title_en) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeOrder = async () => {
    setIsChange(true);
    try {
      const response = await changeOrder({ product_solution_id: formData.id, order: formData.order });
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsChange(false);
      props.toggleModal();
    }
  };

  const onReset = () => {
    setFormData({
      title: '',
      title_en: '',
      order: '',
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);

    try {
      const response = isEdit
        ? await updateSolutionService({ id: formData.id, title: formData.title, title_en: formData.title_en })
        : await addSolutionService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      onReset();
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Solution' : 'Add Solution'}
      visible={props.show}
      closeModal={() => {
        // onReset();
        props.toggleModal();
        setIsAnyEmpty(false);
      }}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
          required={true}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
          required={true}
        />
        <div className="d-flex justify-content-end mb-3">
          <CButton style={{ minWidth: 125 }} size="sm" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
          </CButton>
        </div>

        {isEdit ? (
          <>
            <hr />

            <div className="d-flex align-items-end gap-1">
              <FormControl
                type="number"
                label="order"
                title="Order"
                value={formData?.order ? formData.order : ''}
                handlerChange={onChange}
                required={true}
              />
              <CButton size="sm" color="primary" className="mb-3" onClick={handleChangeOrder} disabled={isChange}>
                {isChange ? <CSpinner size="sm" /> : 'Change Order'}
              </CButton>
            </div>
            <hr />

            <div className="mt-4">
              <label className="form-label">Detail</label>
              <CButton
                size="sm"
                variant="outline"
                className="float-end"
                color="primary"
                onClick={() => {
                  setSelectedDetailIndex(null);
                  setSelectedDetailData(formData);
                  setIsShowDetailModal(true);
                  setIsDetailEdit(false);
                }}
              >
                Add Detail
              </CButton>
              <div className="mt-2">
                <CTable bordered responsive>
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Title</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Description</CTableHeaderCell>
                      <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {formData?.detail?.map((d, i) => {
                      return (
                        <CTableRow key={i}>
                          <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                          <CTableDataCell>{d.title}</CTableDataCell>
                          <CTableDataCell>{d.description}</CTableDataCell>
                          <CTableDataCell>
                            <div className="table-action-wrapper">
                              <button className="btn-edit">
                                <CIcon
                                  icon={cilPencil}
                                  onClick={() => {
                                    onEditDetail(d);
                                    setSelectedDetailIndex(i);
                                  }}
                                />
                              </button>
                              <button className="btn-list">
                                <CIcon
                                  icon={cilList}
                                  onClick={() => {
                                    setSelectedDetailData(d);
                                    setIsShowRequestModal(true);
                                  }}
                                />
                              </button>
                              <button className="btn-delete">
                                {isDeleting ? (
                                  <CIcon icon={cilTrash} />
                                ) : (
                                  <CIcon icon={cilTrash} onClick={() => onDeleteDetail(d.id)} />
                                )}
                              </button>
                            </div>
                          </CTableDataCell>
                        </CTableRow>
                      );
                    })}
                  </CTableBody>
                </CTable>
                <AddEditDetail
                  isDetailEdit={isDetailEdit}
                  data={selectedDetailData}
                  show={isShowDetailModal}
                  toggleModal={() => setIsShowDetailModal(false)}
                  changeDetailData={changeDetailData}
                />
                <RequestDemo
                  data={selectedDetailData}
                  show={isShowRequestModal}
                  toggleModal={() => setIsShowRequestModal(false)}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      <>
        <CButton
          size="sm"
          color="secondary"
          onClick={() => {
            // onReset();
            props.toggleModal();
            setIsAnyEmpty(false);
          }}
        >
          Cancel
        </CButton>
      </>
    </AppModal>
  );
}
