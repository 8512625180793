import axiosConfig from './axios';

export const getBootcampClassListService = async () => {
  try {
    const response = await axiosConfig.get('/career/class');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addBootcampClassService = async (data) => {
  try {
    const response = await axiosConfig.post('/career/class', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateBootcampClassService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/career/class/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteBootcampClassService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/career/class/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
