// import { createSlice } from '@reduxjs/toolkit'

// export const authReducer = createSlice({
//   name: 'counter',
//   initialState: {
//     value: 0,
//   },
//   reducers: {
//     increment: (state) => {
//       // Redux Toolkit allows us to write "mutating" logic in reducers. It
//       // doesn't actually mutate the state because it uses the immer library,
//       // which detects changes to a "draft state" and produces a brand new
//       // immutable state based off those changes
//       state.value += 1
//     },
//     decrement: (state) => {
//       state.value -= 1
//     },
//     incrementByAmount: (state, action) => {
//       state.value += action.payload
//     },
//   },
// })

// export const { increment, decrement, incrementByAmount } = authReducer.actions

// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

// export const selectCount = (state) => state.counter.value

// export default authReducer.reducer

import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    sidebarShow: true,
    sidebarUnfoldable: false,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarShow = !state.sidebarShow;
    },
    toggleSideFoldable: (state) => {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    // set(state, { type, ...rest }){
    // 	state.sidebarShow = {...state, ...rest }
    // 	// localStorage.removeItem('userToken') // deletes token from storage
    // 	// state.loading = false
    // 	// state.userInfo = null
    // 	// state.userToken = null
    // 	// state.error = null
    // },
  },
});

export const { toggleSidebar, toggleSideFoldable } = sidebarSlice.actions;

// export const sidebarShow = (state) => state.sidebar.sidebarShow;

export default sidebarSlice.reducer;
