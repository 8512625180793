import { useState, useEffect } from 'react';
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react';
import BannerList from './BannerList';
import AddEditBanner from './AddEditBanner';
import { getInsightBannerListService } from '../../../services/insight-banner';
import Alert from '../../../component/Alert';

export default function InsightBanner() {
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [list, setList] = useState(null);

  const getList = async () => {
    const response = await getInsightBannerListService();
    setList(response.data);
    if (response.status !== 200) {
      setErrorMessage(response.response.data.message || response.message);
    }
  };

  const handleResponse = (res) => {
    setSuccessMessage('');
    setErrorMessage('');

    if (res.status === 200) {
      setSuccessMessage(res.message);
      getList();
    } else {
      setErrorMessage(res.response ? res.response.data.message : res.message);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>Insight Banner</span>
          <CButton
            onClick={() => setIsShowAddModal(true)}
            size="sm"
            className="float-end"
            color="primary"
          >
            Add Banner
          </CButton>
        </CCardHeader>
        <CCardBody>
          <BannerList data={list} onResponse={(res) => handleResponse(res)} />
        </CCardBody>
      </CCard>
      <AddEditBanner
        show={isShowAddModal}
        toggleModal={() => setIsShowAddModal(false)}
        onResponse={(res) => handleResponse(res)}
      />
    </>
  );
}
