import { CButton } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import AppModal from '../../../component/CoreLayout/AppModal';
import FormControl from '../../../component/FormControl/FormControl';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import axiosConfig from '../../../services/axios';

// fetch(
//   // 'https://api.imgbb.com/1/upload?key=334ecea9ec1213784db5cb9a14dac265',
//   `${process.env.REACT_APP_API_URL}/product/experience/sector/upload`,
//   {
//     method: 'POST',
//     body: formData,
//   }
// )

Quill.register('modules/imageUploader', ImageUploader);

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'imageBlot',
];

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('bg_image', file);

        axiosConfig
          .post(
            `${process.env.REACT_APP_API_URL}/product/experience/sector/upload`,
            formData
          )
          .then((result) => {
            resolve(result.data.data.bg_image);
          })
          .catch((error) => {
            reject('Upload failed');
            console.error('Error:', error);
          });
      });
    },
  },
};

export default function AddEditSector(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    description_en: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onChangeDesc = (value) => {
    setFormData({ ...formData, description: value });
  };

  const onChangeDescEn = (value) => {
    setFormData({ ...formData, description_en: value });
  };

  const saveSector = async (event) => {
    event.preventDefault();
    props.changeSectorData(formData);
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Sector' : 'Add Sector'}
      visible={props.show}
      closeModal={props.toggleModal}
    >
      <div>
        <FormControl
          type="text"
          label="name"
          title="Name"
          value={formData?.name ? formData.name : ''}
          handlerChange={onChange}
        />
        <div className="mb-2">
          <label className="form-label">Description</label>
          <div style={{ fontSize: '12px', marginBottom: '5px' }}>
            <i>*Pastikan gambar sudah benar sebelum diinput ke text editor.</i>
          </div>
          <ReactQuill
            value={formData?.description ? formData.description : ''}
            theme="snow"
            onChange={onChangeDesc}
            modules={modules}
            formats={formats}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Description En</label>
          <div style={{ fontSize: '12px', marginBottom: '5px' }}>
            <i>*Pastikan gambar sudah benar sebelum diinput ke text editor.</i>
          </div>
          <ReactQuill
            value={formData?.description_en ? formData.description_en : ''}
            theme="snow"
            onChange={onChangeDescEn}
            modules={modules}
            formats={formats}
          />
        </div>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton size="sm" color="primary" onClick={saveSector}>
          {isEdit ? 'Edit Sector' : 'Add Sector'}
        </CButton>
      </>
    </AppModal>
  );
}
