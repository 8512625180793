import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './component/ProtectedRoute/ProtectedRoute';
import Login from './container/Login/Login';
import './App.scss';
import './assets/scss/style.scss';

const App = () => {

	return (
		<>
			<Routes>
				<Route path='/login' element={<Login />}/>
				<Route path="/*" element={<ProtectedRoute />}/>
			</Routes>
		</>
	);
}

export default App;
