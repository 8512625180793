import { useState, useEffect } from 'react';
import Alert from '../../component/Alert';
import CIcon from '@coreui/icons-react';
import { cilPencil, cilTrash } from '@coreui/icons';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CSpinner,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react';
import {
  getCompanyDataService,
  updateCompanyDataService,
} from '../../services/company-data';
import FormControl from '../../component/FormControl/FormControl';
import AddEditSocmed from './AddEditSocmed';

export default function CompanyData() {
  const [isAdd, setIsAdd] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    sosmed: [],
  });

  const getCompanyData = async () => {
    const response = await getCompanyDataService();
    setFormData(response.data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('email', formData.email);
    formDataObj.append('phone', formData.phone);

    for (let i = 0; i < formData.sosmed.length; i++) {
      for (let j = 0; j < formData.sosmed?.length; j++) {
        if (j === i && formData.sosmed[j].id) {
          formDataObj.append(`sosmed[${j}][id]`, formData.sosmed[j].id);
        }
      }

      formDataObj.append(`sosmed[${i}][name]`, formData.sosmed[i].name);

      formDataObj.append(`sosmed[${i}][url]`, formData.sosmed[i].url);

      typeof formData.sosmed[i].icon !== 'string' &&
        formDataObj.append(`sosmed[${i}][icon]`, formData.sosmed[i].icon);
    }

    try {
      const response = await updateCompanyDataService(formDataObj, formData.id);
      if (response.status === 200) {
        setSuccessMessage(response.message);
        getCompanyData();
      }
    } catch (error) {
      setErrorMessage(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onDelete = (index) => {
    const copyFormData = { ...formData };
    copyFormData.sosmed.splice(index, 1);
    setFormData({
      ...formData,
      copyFormData,
    });
  };

  const onEdit = (data) => {
    setIsShowModal(true);
    setSelectedData(data);
  };

  const changeSocMedData = (data) => {
    const newData = { ...data };
    setIsShowModal(false);
    if (selectedIndex !== null) {
      const copyData = { ...formData };
      copyData.sosmed[selectedIndex] = newData;
      setFormData(copyData);
      setSelectedData(null);
      setSelectedIndex(null);
    } else {
      const copyData = { ...formData };
      copyData.sosmed = formData.sosmed;
      copyData.sosmed.push(newData);
      setFormData(copyData);
      setSelectedData(null);
    }
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>Company Data</span>
        </CCardHeader>
        <CCardBody>
          <div>
            <FormControl
              type="text"
              label="email"
              title="Email"
              value={formData?.email ? formData.email : ''}
              handlerChange={onChange}
            />
            <FormControl
              type="text"
              label="phone"
              title="Phone"
              value={formData?.phone ? formData.phone : ''}
              handlerChange={onChange}
            />
          </div>
          <div className="mt-4 mb-2">
            <label className="form-label">Social Media</label>
            <CButton
              onClick={() => {
                setIsAdd(true);
                setSelectedIndex(null);
                setIsShowModal(true);
              }}
              size="sm"
              className="float-end"
              color="primary"
            >
              Add Social Media
            </CButton>
          </div>
          <CTable bordered responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                {/* <CTableHeaderCell scope="col">Icon</CTableHeaderCell> */}
                <CTableHeaderCell scope="col">URL</CTableHeaderCell>
                <CTableHeaderCell scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {formData.sosmed?.map((d, i) => {
                return (
                  <CTableRow key={i}>
                    <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                    <CTableDataCell>{d.name}</CTableDataCell>
                    {/* <CTableDataCell>
                      <img width={100} src={d.icon} alt="" />
                    </CTableDataCell> */}
                    <CTableDataCell>
                      <a href={d.url} rel="noreferrer" target="_blank">
                        {d.url}
                      </a>
                    </CTableDataCell>
                    <CTableDataCell>
                      <div className="table-action-wrapper">
                        <button className="btn-edit">
                          <CIcon
                            icon={cilPencil}
                            onClick={() => {
                              onEdit(d);
                              setSelectedIndex(i);
                              setIsAdd(false);
                            }}
                          />
                        </button>
                        <button className="btn-delete">
                          <CIcon icon={cilTrash} onClick={() => onDelete(i)} />
                        </button>
                      </div>
                    </CTableDataCell>
                  </CTableRow>
                );
              })}
            </CTableBody>
          </CTable>
          <CButton
            size="sm"
            style={{ minWidth: 125 }}
            color="primary"
            onClick={handleSubmit}
          >
            {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
          </CButton>
        </CCardBody>
      </CCard>
      <AddEditSocmed
        isAdd={isAdd}
        data={selectedData}
        show={isShowModal}
        toggleModal={() => setIsShowModal(false)}
        changeSocMedData={changeSocMedData}
      />
    </>
  );
}
