import axiosConfig from './axios';

export const getTestimonialListService = async () => {
  try {
    const response = await axiosConfig.get('/home/testimonial');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addTestimonialService = async (data) => {
  try {
    const response = await axiosConfig.post('/home/testimonial', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateTestimonialService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/home/testimonial/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteTestimonialService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/home/testimonial/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
