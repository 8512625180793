import { useState, useEffect } from 'react';
import AppModal from '../../component/CoreLayout/AppModal';
import FormControl from '../../component/FormControl/FormControl';
import InputImage from '../../component/InputImage/InputImage';
import { CButton } from '@coreui/react';

export default function AddEditSocmed(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    url: '',
    icon: '',
  });

  useEffect(() => {
    // setFormData(props.data ?? { ...props.data });
    // setIsEdit(props.data ? true : false);
    if (props.isAdd) {
      setFormData({
        name: '',
        url: '',
        icon: '',
      });
      setIsEdit(false);
    } else {
      setFormData(props.data);
      setIsEdit(true);
    }
  }, [props]);

  const onChange = (event) => {
    const name = event.target.name;
    const value = name === 'icon' ? event.target.files[0] : event.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const saveSocMed = async (event) => {
    event.preventDefault();
    props.changeSocMedData(formData);
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Social Media' : 'Add Social Media'}
      visible={props.show}
      closeModal={props.toggleModal}
    >
      <div>
        <label className="form-label">Icon</label>
        <InputImage
          imgSrc={formData?.icon ? formData.icon : ''}
          name="icon"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="name"
          title="Name"
          value={formData?.name ? formData.name : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="url"
          title="URL"
          value={formData?.url ? formData.url : ''}
          handlerChange={onChange}
        />
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton size="sm" color="primary" onClick={saveSocMed}>
          {isEdit ? 'Edit Social Media' : 'Add Social Media'}
        </CButton>
      </>
    </AppModal>
  );
}
