import { useState, useEffect } from 'react';
import Alert from '../../../component/Alert';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CSpinner,
  CFormTextarea,
} from '@coreui/react';
import {
  getBannerListService,
  updateBannerService,
} from '../../../services/about-banner';
import FormControl from '../../../component/FormControl/FormControl';
import InputImage from '../../../component/InputImage/InputImage';

export default function AboutBanner() {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    bg_image: '',
    title: '',
    title_en: '',
    description: '',
    description_en: '',
  });

  const getBannerData = async () => {
    const response = await getBannerListService();
    setFormData(response.data);
    if (response.status !== 200) {
      setErrorMessage(response.response.data.message || response.message);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    const formDataObj = new FormData();
    typeof formData.bg_image !== 'string' &&
      formDataObj.append('bg_image', formData.bg_image);
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);

    try {
      const response = await updateBannerService(formDataObj, formData.id);
      if (response.status === 200) {
        setSuccessMessage(response.message);
        getBannerData();
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }

    setTimeout(() => {
      setSuccessMessage('');
      setErrorMessage('');
    }, 3000);
  };

  const onChange = (event) => {
    const name = event.target.name;
    let value = '';
    if (name === 'bg_image') {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getBannerData();
  }, []);

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>Banner</span>
        </CCardHeader>
        <CCardBody>
          {formData && (
            <>
              <div>
                <InputImage
                  imgSrc={formData?.bg_image ? formData.bg_image : ''}
                  name="bg_image"
                  changeInputImage={onChange}
                />
                <FormControl
                  type="text"
                  label="title"
                  title="Title"
                  value={formData?.title ? formData.title : ''}
                  handlerChange={onChange}
                />
                <FormControl
                  type="text"
                  label="title_en"
                  title="Title En"
                  value={formData?.title_en ? formData.title_en : ''}
                  handlerChange={onChange}
                />
                <CFormTextarea
                  className="mb-2"
                  label="Description"
                  rows="2"
                  name="description"
                  value={formData?.description ? formData.description : ''}
                  onChange={onChange}
                ></CFormTextarea>
                <CFormTextarea
                  className="mb-2"
                  label="Description"
                  rows="2"
                  name="description_en"
                  value={
                    formData?.description_en ? formData.description_en : ''
                  }
                  onChange={onChange}
                ></CFormTextarea>
              </div>
              <CButton
                style={{ minWidth: 125 }}
                color="primary"
                onClick={handleSubmit}
              >
                {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
              </CButton>
            </>
          )}
        </CCardBody>
      </CCard>
    </>
  );
}
