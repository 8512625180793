import React from 'react';
import CIcon from '@coreui/icons-react';
import { cilChartPie, cilPuzzle } from '@coreui/icons';
import { CNavGroup, CNavItem } from '@coreui/react';

const _nav = [
  {
    component: CNavItem,
    name: 'Company Data',
    to: '/company-data',
    icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Employee',
    to: '/employee',
    icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: 'Home',
    to: '/',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Banner',
        to: '/home/banner',
      },
      {
        component: CNavItem,
        name: 'Testimoni',
        to: '/home/testimoni',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'About Us',
    to: '/about-us',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Banner',
        to: '/about-us/banner',
      },
      {
        component: CNavItem,
        name: 'Summary',
        to: '/about-us/summary',
      },
      {
        component: CNavItem,
        name: 'Milestone',
        to: '/about-us/milestone',
      },
      {
        component: CNavItem,
        name: 'Founder',
        to: '/about-us/founder',
      },
      {
        component: CNavItem,
        name: 'Partner',
        to: '/about-us/partner',
      },
      {
        component: CNavItem,
        name: 'Client',
        to: '/about-us/client',
      },
      {
        component: CNavItem,
        name: 'Category',
        to: '/about-us/category',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Product',
    to: '/product',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Banner',
        to: '/product/banner',
      },
      {
        component: CNavItem,
        name: 'Experience',
        to: '/product/experience',
      },
      {
        component: CNavItem,
        name: 'Service Banner',
        to: '/product/service-banner',
      },
      {
        component: CNavItem,
        name: 'IT Profesional',
        to: '/product/it-pro',
      },
      {
        component: CNavItem,
        name: 'Solution',
        to: '/product/solution',
      },
      // {
      //   component: CNavItem,
      //   name: 'Request',
      //   to: '/product/request',
      // },
      // {
      //   component: CNavItem,
      //   name: 'Product & Solutions',
      //   to: '/product/product-solutions',
      // },
    ],
  },
  {
    component: CNavGroup,
    name: 'Career',
    to: '/career',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Banner',
        to: '/career/banner',
      },
      {
        component: CNavItem,
        name: 'Open Job',
        to: '/career/open-job',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Bootcamp',
    to: '/bootcamp',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Gallery',
        to: '/bootcamp/bootcamp-data',
      },
      {
        component: CNavItem,
        name: 'Class',
        to: '/bootcamp/bootcamp-class',
      },
      {
        component: CNavItem,
        name: 'Benefit',
        to: '/bootcamp/bootcamp-benefit',
      },
      // {
      //   component: CNavItem,
      //   name: 'In Numbers',
      //   to: '/bootcamp/bootcamp-in-numbers',
      // },
    ],
  },
  {
    component: CNavGroup,
    name: 'Insight',
    to: '/insight',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Banner',
        to: '/insight/banner',
      },
      {
        component: CNavItem,
        name: 'Case Study',
        to: '/insight/case-study',
      },
      {
        component: CNavItem,
        name: 'News',
        to: '/insight/news',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Contact Us',
    to: '/contact-us',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Office',
        to: '/contact-us/office',
      },
    ],
  },
  {
    component: CNavGroup,
    name: 'Applicant',
    to: '/applicant',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Bootcamp',
        to: '/applicant/bootcamp',
      },
      {
        component: CNavItem,
        name: 'Fulltime',
        to: '/applicant/fulltime',
      },
    ],
  },
  {
    component: CNavItem,
    name: 'Opty',
    to: '/opty',
    icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Theme',
    to: '/theme',
    icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  },
];

export default _nav;
