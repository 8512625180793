import axiosConfig from './axios';

export const getInsightBannerListService = async () => {
  try {
    const response = await axiosConfig.get('/insight/banner');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addInsightBannerService = async (data) => {
  try {
    const response = await axiosConfig.post('/insight/banner', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateInsightBannerService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/insight/banner/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteInsightBannerService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/insight/banner/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
