import React from 'react';
import { CFormInput, CFormLabel } from '@coreui/react';
import './FormControl.scss';

const FormControl = ({ label, title, value, handlerChange, type, required = false }) => {
  return (
    <div className="form-control-wrapper">
      <CFormLabel htmlFor={label}>
        {title} {required ? <span className="required">*</span> : ''}
      </CFormLabel>
      <CFormInput name={label} value={value} onChange={handlerChange} type={type} />
    </div>
  );
};

export default FormControl;
