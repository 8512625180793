import { useState } from 'react';
import CIcon from '@coreui/icons-react';
import { cilPencil, cilTrash } from '@coreui/icons';
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react';
import { deleteJobService } from '../../../services/job';
import { AddEditOpenJob } from './AddEditOpenJob';
import DeletePrompt from '../../../component/DeletePrompt';

export default function OpenJobList(props) {
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isShowDeletePrompt, setIsShowDeletePrompt] = useState(false);
  const [dataId, setDataId] = useState();

  const onDelete = (id) => {
    setDataId(id);
    setIsShowDeletePrompt(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteJobService(dataId);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  const onEdit = (data) => {
    setIsShowAddModal(true);
    setSelectedData(data);
  };

  return (
    <>
      <CTable bordered responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">No.</CTableHeaderCell>
            <CTableHeaderCell scope="col">Role Name</CTableHeaderCell>
            <CTableHeaderCell scope="col">Department</CTableHeaderCell>
            <CTableHeaderCell scope="col">Job Type</CTableHeaderCell>
            {/* <CTableHeaderCell scope="col">Job Description</CTableHeaderCell> */}
            {/* <CTableHeaderCell scope="col">Job Requirement</CTableHeaderCell> */}
            <CTableHeaderCell scope="col">Experience Year</CTableHeaderCell>
            <CTableHeaderCell scope="col">Expired Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Action</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {props?.data?.map((d, i) => {
            return (
              <CTableRow key={i}>
                <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                <CTableDataCell>{d.role_name}</CTableDataCell>
                <CTableDataCell style={{ textTransform: 'capitalize' }}>
                  {d.department}
                </CTableDataCell>
                <CTableDataCell style={{ textTransform: 'capitalize' }}>
                  {d.job_type}
                </CTableDataCell>
                {/* <CTableDataCell>
                  <span dangerouslySetInnerHTML={{ __html: d.job_desc }}></span>
                </CTableDataCell>
                <CTableDataCell>
                  <span
                    dangerouslySetInnerHTML={{ __html: d.job_requirement }}
                  ></span>
                </CTableDataCell> */}
                <CTableDataCell>{d.experience_year}</CTableDataCell>
                <CTableDataCell>{d.expired_date}</CTableDataCell>
                <CTableDataCell>
                  <div className="table-action-wrapper">
                    <button className="btn-edit">
                      <CIcon icon={cilPencil} onClick={() => onEdit(d)} />
                    </button>
                    <button className="btn-delete">
                      <CIcon icon={cilTrash} onClick={() => onDelete(d.id)} />
                    </button>
                  </div>
                </CTableDataCell>
              </CTableRow>
            );
          })}
        </CTableBody>
      </CTable>
      <AddEditOpenJob
        data={selectedData}
        show={isShowAddModal}
        toggleModal={() => setIsShowAddModal(false)}
        onResponse={(res) => props.onResponse(res)}
      />
      <DeletePrompt
        show={isShowDeletePrompt}
        toggleModal={() => setIsShowDeletePrompt(false)}
        yes={handleDelete}
      />
    </>
  );
}
