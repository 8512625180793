import { CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import React from 'react';
import Alert from '../Alert';

const AppModal = ({ size = 'lg', visible, closeModal, title, isAnyEmpty, children }) => {
  return (
    <CModal size={size} backdrop="static" visible={visible} onClose={closeModal}>
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {isAnyEmpty && <Alert errorMessage="Data tidak boleh ada yang kosong." />}
        {children[0]}
      </CModalBody>
      <CModalFooter>{children[1]}</CModalFooter>
    </CModal>
  );
};

export default AppModal;
