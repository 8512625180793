import React, { useState, useEffect } from 'react';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import Alert from '../../component/Alert';
import DeletePrompt from '../../component/DeletePrompt';
import {
  deleteFulltimeService,
  getFulltimeListService,
} from '../../services/fulltime';
import JwPagination from 'jw-react-pagination';
import { getJobListService } from '../../services/job';
// import DownloadLink from 'react-download-link';

const FulltimeApplicant = () => {
  const [list, setList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [pageOfItems, setPageOfItems] = useState([]);
  const [isShowDeletePrompt, setIsShowDeletePrompt] = useState(false);
  const [dataId, setDataId] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const getList = async () => {
    const response = await getFulltimeListService();
    setList(response.data.map((d, i) => ({ index: i + 1, ...d })));
    if (response.status !== 200) {
      setErrorMessage(response.response.data.message || response.message);
    }
  };

  const getJobList = async () => {
    const response = await getJobListService();
    setJobList(response.data);
    if (response.status !== 200) {
      setErrorMessage(response.response.data.message || response.message);
    }
  };

  useEffect(() => {
    getList();
    getJobList();
  }, []);

  const onDelete = (id) => {
    setDataId(id);
    setIsShowDeletePrompt(true);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteFulltimeService(dataId);
      handleResponse(response);
    } catch (error) {
      handleResponse(error);
    } finally {
      setIsShowDeletePrompt(false);
    }
  };

  const handleResponse = (res) => {
    setSuccessMessage('');
    setErrorMessage('');

    if (res.status === 200) {
      setSuccessMessage(res.message);
      getList();
    } else {
      setErrorMessage(res.response ? res.response.data.message : res.message);
    }
  };

  const onChangePage = (pageOfItems) => {
    setPageOfItems(pageOfItems);
  };

  return (
    <>
      <Alert successMessage={successMessage} errorMessage={errorMessage} />
      <CCard>
        <CCardHeader>
          <span>List Fulltime Applicant</span>
        </CCardHeader>
        <CCardBody>
          <CTable bordered responsive>
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                <CTableHeaderCell scope="col">Name</CTableHeaderCell>
                <CTableHeaderCell scope="col">Email</CTableHeaderCell>
                <CTableHeaderCell scope="col">No Handphone</CTableHeaderCell>
                <CTableHeaderCell scope="col">Position</CTableHeaderCell>
                <CTableHeaderCell scope="col">Resume</CTableHeaderCell>
                <CTableHeaderCell scope="col">Action</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {pageOfItems &&
                pageOfItems.map((data) => {
                  return (
                    <CTableRow key={data.index}>
                      <CTableHeaderCell scope="row">
                        {data.index}
                      </CTableHeaderCell>
                      <CTableDataCell>{data.name}</CTableDataCell>
                      <CTableDataCell>{data.email}</CTableDataCell>
                      <CTableDataCell>{data.handphone}</CTableDataCell>
                      <CTableDataCell>
                        {jobList.find(
                          (jl) => Number(jl.id) === Number(data.id_job)
                        )?.role_name ?? 'N/A'}
                      </CTableDataCell>
                      <CTableDataCell>
                        <a
                          target="_blank"
                          href={data.cv}
                          download
                          rel="noreferrer"
                        >
                          Download Here
                        </a>
                        {/* <DownloadLink
                          label="Download Here"
                          filename={data.cv}
                          exportFile={() => "My cached data"}
                        /> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="table-action-wrapper">
                          <button className="btn-delete">
                            <CIcon
                              icon={cilTrash}
                              onClick={() => onDelete(data.id)}
                            />
                          </button>
                        </div>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
            </CTableBody>
          </CTable>
          <JwPagination pageSize={5} items={list} onChangePage={onChangePage} />
          <DeletePrompt
            show={isShowDeletePrompt}
            toggleModal={() => setIsShowDeletePrompt(false)}
            yes={handleDelete}
          />
        </CCardBody>
      </CCard>
    </>
  );
};

export default FulltimeApplicant;
