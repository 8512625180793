import axiosConfig from './axios';

export const getServiceBannerService = async () => {
  try {
    const response = await axiosConfig.get('/product/service-banner');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateServiceBannerService = async (data) => {
  try {
    const response = await axiosConfig.post(
      `/product/service-banner`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
