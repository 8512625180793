import axiosConfig from './axios';

export const getMilestoneListService = async () => {
  try {
    const response = await axiosConfig.get('/about/milestone');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addMilestoneService = async (data) => {
  try {
    const response = await axiosConfig.post('/about/milestone', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateMilestoneService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/about/milestone/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteMilestoneService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/about/milestone/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
