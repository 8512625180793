import axiosConfig from './axios';

export const getOfficeListService = async () => {
  try {
    const response = await axiosConfig.get('/contact/office');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const addOfficeService = async (data) => {
  try {
    const response = await axiosConfig.post('/contact/office', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateOfficeService = async (data, id) => {
  try {
    const response = await axiosConfig.post(
      `/contact/office/${id}?_method=PUT`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteOfficeService = async (id) => {
  try {
    const response = await axiosConfig.delete(`/contact/office/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
