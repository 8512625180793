import { useState, useEffect } from 'react';
import {
  CButton,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CSpinner,
  CFormTextarea,
} from '@coreui/react';
import { cilPencil, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  addProductITProService,
  updateProductITProService,
} from '../../../services/itpro';
import AppModal from '../../../component/CoreLayout/AppModal';
import InputImage from '../../../component/InputImage/InputImage';
import FormControl from '../../../component/FormControl/FormControl';
import AddEditCompetences from './AddEditCompetences';

export default function AddEditService(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [isShowCompetencesModal, setIsShowCompetencesModal] = useState(false);
  const [selectedCompetencesData, setSelectedCompetencesData] = useState(null);
  const [selectedCompetencesIndex, setSelectedCompetencesIndex] =
    useState(null);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    description: '',
    description_en: '',
    logo: '',
    kompetensi: [],
  });

  useEffect(() => {
    props.data && setFormData(props.data);
    // setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    let value = '';
    if (name === 'logo') {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const changeCompetencesData = (data) => {
    const newData = { ...data };
    setIsShowCompetencesModal(false);
    if (selectedCompetencesIndex !== null) {
      const copyData = { ...formData };
      copyData.kompetensi[selectedCompetencesIndex] = newData;
      setFormData(copyData);
      setSelectedCompetencesData(null);
      setSelectedCompetencesIndex(null);
    } else {
      const copyData = { ...formData };
      copyData.kompetensi = formData.kompetensi;
      copyData.kompetensi.push(newData);
      setFormData(copyData);
      setSelectedCompetencesData(null);
    }
  };

  const onEditCompetences = (data) => {
    setIsShowCompetencesModal(true);
    setSelectedCompetencesData(data);
  };

  const onDeleteCompetences = (index) => {
    const copyFormData = { ...formData };
    copyFormData.kompetensi.splice(index, 1);
    setFormData({
      ...formData,
      copyFormData,
    });
  };

  const checkInput = () => {
    if (
      formData.title &&
      formData.title_en &&
      formData.description &&
      formData.description_en &&
      formData.logo &&
      formData.kompetensi.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);
    typeof formData.logo !== 'string' &&
      formDataObj.append('logo', formData.logo);

    for (var i = 0; i < formData.kompetensi.length; i++) {
      for (let j = 0; j < props?.data?.kompetensi?.length; j++) {
        if (j === i && props?.data?.kompetensi[j].id) {
          formDataObj.append(
            `kompetensi[${j}][id]`,
            props?.data?.kompetensi[j].id
          );
        }
      }
      formDataObj.append(
        `kompetensi[${i}][title]`,
        formData.kompetensi[i].title
      );
      formDataObj.append(
        `kompetensi[${i}][title_en]`,
        formData.kompetensi[i].title_en
      );
      formDataObj.append(
        `kompetensi[${i}][content]`,
        formData.kompetensi[i].content
      );
      formDataObj.append(
        `kompetensi[${i}][content_en]`,
        formData.kompetensi[i].content_en
      );
    }

    try {
      const response = isEdit
        ? await updateProductITProService(formDataObj, formData.id)
        : await addProductITProService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        title: '',
        title_en: '',
        description: '',
        description_en: '',
        logo: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Service' : 'Add Service'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Logo</label>
        <InputImage
          imgSrc={formData?.logo ? formData.logo : ''}
          name="logo"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Description"
          rows="2"
          name="description"
          value={formData?.description ? formData.description : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Description"
          rows="2"
          name="description_en"
          value={formData?.description_en ? formData.description_en : ''}
          onChange={onChange}
        ></CFormTextarea>
        <div className="mt-4">
          <label className="form-label">Competences</label>
          <CButton
            size="sm"
            variant="outline"
            className="float-end"
            color="primary"
            onClick={() => {
              setSelectedCompetencesIndex(null);
              setSelectedCompetencesData(null);
              setIsShowCompetencesModal(true);
            }}
          >
            Add Competences
          </CButton>
          <div className="mt-2">
            <CTable bordered responsive>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">No.</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Title</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Content</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {formData?.kompetensi?.map((d, i) => {
                  return (
                    <CTableRow key={i}>
                      <CTableHeaderCell scope="row">{i + 1}</CTableHeaderCell>
                      <CTableDataCell>{d.title}</CTableDataCell>
                      <CTableDataCell>{d.content}</CTableDataCell>
                      <CTableDataCell>
                        <div className="table-action-wrapper">
                          <button className="btn-edit">
                            <CIcon
                              icon={cilPencil}
                              onClick={() => {
                                onEditCompetences(d);
                                setSelectedCompetencesIndex(i);
                              }}
                            />
                          </button>
                          <button className="btn-delete">
                            <CIcon
                              icon={cilTrash}
                              onClick={() => onDeleteCompetences(i)}
                            />
                          </button>
                        </div>
                      </CTableDataCell>
                    </CTableRow>
                  );
                })}
              </CTableBody>
            </CTable>
            <AddEditCompetences
              data={selectedCompetencesData}
              show={isShowCompetencesModal}
              toggleModal={() => setIsShowCompetencesModal(false)}
              changeCompetencesData={changeCompetencesData}
            />
          </div>
        </div>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          style={{ minWidth: 125 }}
          size="sm"
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}
