import { useState, useEffect } from 'react';
import FormControl from '../../../component/FormControl/FormControl';
import InputImage from '../../../component/InputImage/InputImage';
import AppModal from '../../../component/CoreLayout/AppModal';
import { CButton, CSpinner, CFormTextarea } from '@coreui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  addCaseStudyService,
  updateCaseStudyService,
} from '../../../services/case-study';
// import { quillFormats, quillModules } from '../../../helpers';

export default function AddEditCaseStudy(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAnyEmpty, setIsAnyEmpty] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    title_en: '',
    subtitle: '',
    subtitle_en: '',
    description: '',
    description_en: '',
    image_logo_principle: '',
    bg_image: '',
  });

  useEffect(() => {
    setFormData(props.data ?? { ...props.data });
    setIsEdit(props.data ? true : false);
  }, [props.data]);

  const onChange = (event) => {
    const name = event.target.name;
    const value =
      name === 'bg_image' || name === 'image_logo_principle'
        ? event.target.files[0]
        : event.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onChangeDesc = (value) => {
    setFormData({ ...formData, description: value });
  };

  const onChangeDescEn = (value) => {
    setFormData({ ...formData, description_en: value });
  };

  const checkInput = () => {
    if (
      formData.title &&
      formData.title_en &&
      formData.subtitle &&
      formData.subtitle_en &&
      formData.description &&
      formData.description_en &&
      formData.image_logo_principle &&
      formData.bg_image
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!checkInput()) {
      setIsAnyEmpty(true);
      return;
    }

    setIsAnyEmpty(false);
    setIsSubmitting(true);
    const formDataObj = new FormData();
    formDataObj.append('title', formData.title);
    formDataObj.append('title_en', formData.title_en);
    formDataObj.append('subtitle', formData.subtitle);
    formDataObj.append('subtitle_en', formData.subtitle_en);
    formDataObj.append('description', formData.description);
    formDataObj.append('description_en', formData.description_en);
    typeof formData.bg_image !== 'string' &&
      formDataObj.append('bg_image', formData.bg_image);
    typeof formData.image_logo_principle !== 'string' &&
      formDataObj.append('image_logo_principle', formData.image_logo_principle);

    try {
      const response = isEdit
        ? await updateCaseStudyService(formDataObj, formData.id)
        : await addCaseStudyService(formDataObj);
      props.onResponse(response);
    } catch (error) {
      props.onResponse(error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        title: '',
        title_en: '',
        subtitle: '',
        subtitle_en: '',
        description: '',
        description_en: '',
        image_logo_principle: '',
        bg_image: '',
      });
      props.toggleModal();
    }
  };

  return (
    <AppModal
      title={isEdit ? 'Edit Case Study' : 'Add Case Study'}
      visible={props.show}
      closeModal={props.toggleModal}
      isAnyEmpty={isAnyEmpty}
    >
      <div>
        <label className="form-label">Background Image</label>
        <InputImage
          imgSrc={formData?.bg_image ? formData.bg_image : ''}
          name="bg_image"
          changeInputImage={onChange}
        />
        <label className="form-label">Logo Principle Image</label>
        <InputImage
          imgSrc={
            formData?.image_logo_principle ? formData.image_logo_principle : ''
          }
          name="image_logo_principle"
          changeInputImage={onChange}
        />
        <FormControl
          type="text"
          label="title"
          title="Title"
          value={formData?.title ? formData.title : ''}
          handlerChange={onChange}
        />
        <FormControl
          type="text"
          label="title_en"
          title="Title En"
          value={formData?.title_en ? formData.title_en : ''}
          handlerChange={onChange}
        />
        <CFormTextarea
          className="mb-2"
          label="Subtitle"
          rows="2"
          name="subtitle"
          value={formData?.subtitle ? formData.subtitle : ''}
          onChange={onChange}
        ></CFormTextarea>
        <CFormTextarea
          className="mb-2"
          label="Subtitle En"
          rows="2"
          name="subtitle_en"
          value={formData?.subtitle_en ? formData.subtitle_en : ''}
          onChange={onChange}
        ></CFormTextarea>
        <div className="mb-2">
          <label className="form-label">Description</label>
          <ReactQuill
            value={formData?.description ? formData.description : ''}
            theme="snow"
            onChange={onChangeDesc}
            // modules={quillModules}
            // formats={quillFormats}
          />
        </div>
        <div className="mb-2">
          <label className="form-label">Description En</label>
          <ReactQuill
            value={formData?.description_en ? formData.description_en : ''}
            theme="snow"
            onChange={onChangeDescEn}
            // modules={quillModules}
            // formats={quillFormats}
          />
        </div>
      </div>
      <>
        <CButton size="sm" color="secondary" onClick={props.toggleModal}>
          Cancel
        </CButton>
        <CButton
          style={{ minWidth: 125 }}
          size="sm"
          color="primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? <CSpinner size="sm" /> : 'Save Changes'}
        </CButton>
      </>
    </AppModal>
  );
}

// AddEditCaseStudy.modules = quillModules;
// AddEditCaseStudy.formats = quillFormats;
